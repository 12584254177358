<script setup>
import router from '@/router'
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { onMounted } from 'vue'
import SectionTitle from '../SectionTitle.vue'
import ProductCard from './ProductCard.vue'

const props = defineProps({
  isSuggestions: {
    type: Boolean,
    required: false,
  },
  quantity: {
    type: Number,
    required: false,
    default: 16,
  },
})

const productsListStore = useProductsListStore()
const favoriteProducts = localStorage.getItem('favorite_products') ? JSON.parse(localStorage.getItem('favorite_products')) : []

const searchQuery = computed(() => productsListStore.getSearchQuery)
const items = computed(() => productsListStore.getPopularRecomendations)
const categories = productsListStore.getCategories
const suggestedProducts = computed(() => productsListStore.getSuggestedProducts.filter(el => !favoriteProducts.includes(el.item_id) ))


const searchProducts =() => {
  searchQuery.value.page = 1
  searchQuery.value.page_size = 5
  searchQuery.value.sort = "default"
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.fetchPopularProductsCatalog(false)

}

onMounted(() => {
  searchProducts()
})
</script>

<template>
  <section id="recommendations-popular">
    <VCard
      v-if="categories && categories.length || props.isSuggestions"
      class="px-4 my-10"

      style="border-radius: 26px;"
    >
      <div class="d-flex flex-wrap gap-2 align-center justify-space-between notranslate">
        <div class="d-flex my-1 align-center">
          <SectionTitle
            class="mr-2 w-100"
            :class="{'mb-0' : props.isSuggestions, 'my-5': !$vuetify.display.mobile, 'mt-5': $vuetify.display.mobile}"
            :title="props.isSuggestions ? 'Esses produtos oferecem uma ótima oportunidade de faturamento.' : 'Produtos em Alta!'"
          />
        </div>
      </div>
      <div class="mt-1">
        <div v-if="props.isSuggestions">
          <VRow
            v-if="suggestedProducts.length > 0"
            class="d-flex py-7 flex-wrap"
          >
            <VCol
              v-for="(product, idx) in suggestedProducts.slice(0, props.quantity)"
              :key="idx"
              class="pa-2"
              cols="12"
              md="3"
            >
              <ProductCard
                :product="product"
                is-suggestions
                :is-offer="parseFloat(product.price) === Math.min(...suggestedProducts.slice(0, props.quantity).map(p => parseFloat(p.price)))"
              />
            </VCol>
          </VRow>
          <VRow v-else>
            <VCol
              v-for="n in props.quantity"
              :key="n"
              class="pa-2"
            >
              <VSkeletonLoader
                class="mx-auto"
                max-width="300"
                type="image, list-item-two-line"
              />
            </VCol>
          </VRow>
        </div>
        <div v-else>
          <VRow
            v-if="items.length > 0"
            class="d-flex py-7 flex-wrap justify-end"
          >
            <VCol
              v-for="(product, idx) in items.slice(0, $vuetify.display.mobile ? 7 : 16)"
              :key="idx"
              class="pa-2"
              cols="12"
              md="3"
            >
              <ProductCard :product="product" />
            </VCol>
            <VBtn
              variant="text"
              append-icon="tabler-arrow-right"
              @click="router.push({name:'search-products',query:{'popular_products' : true}})"
            >
              Ver mais
            </VBtn>
          </VRow>
          <VRow
            v-else
            class="my-4"
          >
            <VCol
              v-for="n in 5"
              :key="n"
              class="pa-2"
            >
              <VSkeletonLoader
                class="mx-auto"
                max-width="300"
                type="image, list-item-three-line"
              />
            </VCol>
          </VRow>
        </div>
      </div>
    </VCard>
  </section>
</template>

<style scoped lang="scss">
.card {
  border-radius: 16px;
  background-color: #222020;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  &__title {
    color: white;
    font-family: Poppins, sans-serif !important;
    font-size: 1.2rem;
    font-weight: 600 !important;
    word-wrap: normal;
  }
}
</style>
