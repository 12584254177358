<script setup lang="ts">
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { useCartStore } from '@/views/apps/cart/useCartStore'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  isAdmin: {
    type: Boolean,
    default: false,
  },
  isSite: {
    type: Boolean,
    default: false,
  },
  isMenu: {
    type: Boolean,
    default: false,
  },
})

const authStore = useAuthStore()

const accessToken = computed(() => authStore.getAccessToken)

const route = useRoute()

const localUser = computed(() => authStore.getUserData)

const cartStore = useCartStore()
const bid = computed(() => cartStore.getBid)

const getSubQuantity = variations => {
  let total = 0
  variations.forEach(variation => {
    total += parseInt(variation.quantity) > 0 ? parseInt(variation.quantity) : 0
  })

  return total
}

const reduceColorName = (name, qtd) => {
  return name.length > qtd ? name.substring(0, qtd) + "..." : name
}

const formatPrice = (price, quantity, applyBRLFormat = true) => {
  if (price) {
    
    let total = (price * quantity) * bid.value
    total =  Number(total * (applyBRLFormat ? 2 : 1)).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
     

    return bid.value ? total : `¥ ${price}`
  }

  return 'R$ 0,00'
}

const viewProductOnSite = item => {
  window.open(`/details/${item.id}`, '_blank')
}

const viewProductOn1688 = item => {
  window.open(item.product_url, '_blank')
}

const phoneConfirmed = computed(() =>{
  return authStore.phoneConfirmed
})

const viewRegisterDialog = async () => {
  if (accessToken.value && phoneConfirmed.value ) return 
  if(!phoneConfirmed.value && accessToken.value){
    authStore.setWindowRegister(1)
  }
  authStore.setIsRegisterDialogVisible(true)
}

watch(props.items, newValue =>{
  for(let item in newValue){
    for(let variation in props.items[item].variations){

      if(props.items[item].variations[variation].quantity == '' || Number.isNaN(props.items[item].variations[variation].quantity )){
        props.items[item].variations[variation].quantity = 1
      }
    }
  }

}, { deep: true })

const onClickStop = el => {
  el.stopPropagation()
}

const blurActive = computed(() =>{
  return !accessToken.value || !phoneConfirmed.value
})
</script>

<template>
  <section>
    <section
      v-for="(item, idx) in items"
      :key="idx"
      :class="{'mb-4': !isMenu}"
    >
      <section class="pb-4 d-flex align-center justify-space-between">
        <p class="notranslate mb-0">
          {{ 
            item.variations.length == 1 
              ? `${item.variations.length} produto` 
              : `${item.variations.length} produtos` 
          }} 
          |
          {{ 
            getSubQuantity(item.variations) == 1 
              ? `${getSubQuantity(item.variations)} unidade` 
              : `${getSubQuantity(item.variations)} unidades` 
          }}
        </p>
        <VIcon
          v-if="$vuetify.display.mobile && !isAdmin"
          icon="tabler-trash"
          @click.stop="cartStore.removeVariationOfProduct(item, variation)"
        />
      </section>
      <VSheet
        v-for="(variation) in item.variations"
        :key="variation.color.vid"
        flat
        class="mb-2"
      >
        <div class="d-flex justify-space-between gap-10">
          <div
            class="d-flex justify-space-between w-100"
            :class="{ 'flex-wrap flex-md-nowrap flex-column flex-md-row': !$vuetify.display.mobile }"
          >
            <div class="pe-5 py-0 my-0">
              <VImg
                width="100"
                height="100"
                :src="item.image_url"
                class="rounded-lg"
              />
            </div>
            <div class="d-flex flex-column justify-space-between w-100">
              <section>
                <p
                  v-if="item"
                  class="font-weight-bold text-wrap mb-0"
                  :class="{'text-body-1': !isMenu, 'text-body-2 pb-2': isMenu}"
                >
                  {{ reduceColorName(item.name, 150) }}
                </p>
                <p
                  v-if="!isMenu"
                  class="text-body-2 text-wrap"
                >
                  {{ 
                    variation.specification
                      ? reduceColorName(variation.specification.name, 100)
                      : reduceColorName(variation.color.name, 100) }}
                </p>
              </section> 
              <VRow 
                align="center"
                justify="space-between"
                class="d-flex  align-center justify-space-between w-100 notranslate"
              >
                <VCol
                  :class="{'justify-space-between': isMenu}"
                  class="d-flex  align-center gap-14"
                >
                  <section class="h-100">
                    <section class="d-flex align-center gap-2">
                      <p class=" font-weight-bold mb-0">
                        🇨🇳   {{ formatPrice(variation.price, variation.quantity,false) }}
                      </p>
                    </section>
                  </section>
                  <section class="h-100">
                    <section
                      :class="{'cursor-pointer': blurActive}"
                      @click="viewRegisterDialog"
                    >
                      <section class="d-flex align-center gap-2">
                        <p
                          class=" font-weight-bold mb-0"
                          :class="{'price-blur-2' : blurActive}"
                        >
                          🇧🇷 {{ formatPrice(variation.price, variation.quantity,true) }}
                        </p>
                        <section v-if="blurActive">
                          <IconBtn size="25"> 
                            <VIcon
                              icon="tabler-eye" 
                              @click.stop="viewRegisterDialog"
                            />
                          </IconBtn>
                          <VTooltip
                            activator="parent"
                            location="start"
                            class="notranslate"
                          >
                            Clique para ver o preço no Brasil
                          </VTooltip>
                        </section>
                      </section>
                    </section>
                    <VTooltip
                      v-if="accessToken && phoneConfirmed"
                      activator="parent"
                      location="end"
                      class="notranslate"
                    >
                      Preço estimado nacionalizado no Brasil<br>considerando impostos, frete e despesas
                    </VTooltip>
                  </section>
                </VCol>
                <VCol
                  v-if="!isAdmin && $vuetify.display.mobile || isMenu"
                  class="d-flex align-center gap-3"
                >
                  <VTextField
                    v-model="variation.quantity"
                    class="item-quantity"
                    :style="{width: '120px'}"
                    rounded
                    :rules="[(v) => (v && v > 0) || 'Quantidade deve ser maior que 0']"
                    prepend-inner-icon="tabler-minus"
                    append-inner-icon="tabler-plus"
                    @click.stop="onClickStop"
                    @click:prepend-inner.stop="cartStore.handleQuantityChange(variation,item,'decrease')"
                    @click:append-inner.stop="cartStore.handleQuantityChange(variation,item,'increase')"
                    @change="cartStore.handleQuantityChange(variation,item)"
                  />
                  <VIcon
                    v-if="isMenu"
                    icon="tabler-trash"
                    @click.stop="cartStore.removeVariationOfProduct(item, variation)"
                  />
                </VCol>
                
                <VCol
                  v-if="isAdmin"
                  class="d-flex flex-row gap-2"
                >
                  <IconBtn
                    v-if="!isSite"
                    @click="viewProductOnSite(item)"
                  >
                    <VIcon icon="tabler-external-link" />
                    <VTooltip
                      activator="parent"
                      location="start"
                    >
                      Ver produto no site
                    </VTooltip>
                  </IconBtn>
                  <IconBtn
                    v-if="localUser.role === 'admin'"
                    @click="viewProductOn1688(item)"
                  >
                    <img
                      src="/images/logo-1688.png"
                      width="38"
                    >
                    <VTooltip
                      activator="parent"
                      location="start"
                    >
                      Ver produto no 1688
                    </VTooltip>
                  </IconBtn>
                </VCol>
              </VRow>
            </div>
          </div>
          <div
            v-if="!isAdmin && !$vuetify.display.mobile && !isMenu"
            class="d-flex align-end justify-end"
            :style="{width: '200px'}"
          >
            <div class="d-flex align-center justify-space-between gap-2">
              <VTextField
                v-model="variation.quantity"
                class="item-quantity"
                :style="{width: '120px'}"
                rounded
                :rules="[(v) => (v && v > 0) || 'Quantidade deve ser maior que 0']"
                prepend-inner-icon="tabler-minus"
                append-inner-icon="tabler-plus"
                @change="cartStore.handleQuantityChange(variation,item,'change')"
                @click:prepend-inner="cartStore.handleQuantityChange(variation,item,'decrease')"
                @click:append-inner="cartStore.handleQuantityChange(variation,item,'increase')"
              />
              <VIcon
                v-if="route.name != 'home-solicitations'"
                icon="tabler-trash"
                @click.stop="cartStore.removeVariationOfProduct(item, variation)"
              />
            </div>
          </div>
        </div>
      </VSheet>
      <VDivider
        v-if=" items.length > 1 && idx < items.length - 1"
        class="my-5"
      />
    </section>
  </section>
</template>
