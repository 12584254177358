import axios from '@axios'
import { defineStore } from 'pinia'

export const useCartStore = defineStore('CartStore', {
  state: () => ({
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
    bid: 0.8301, // valor da moeda em reais
    isCartMenuOpen: false,
    isCompletedDialogVisible: false,
    confirmUpdateProductMobile: false,
    wasProductInCart: false,
    confirmationDialog: false,
  }),
  getters: {
    // 👉 Getters
    getCart: state => state.cart,
    getCartCount: state => state.cart.length || JSON.parse(localStorage.getItem('cart'))?.length || 0,
    getBid: state => state.bid,
    getIsCompletedDialogVisible: state => state.isCompletedDialogVisible,
    getConfirmUpdateProductMobile: state => state.confirmUpdateProductMobile,
    getWasProductInCart: state => state.wasProductInCart,
    getConfirmationDialog: state => state.confirmationDialog,
  },
  actions: {
    setIsCompletedDialogVisible(isCompletedDialogVisible){
      this.isCompletedDialogVisible = isCompletedDialogVisible
    },
    setConfirmationDialog(value){
      this.confirmationDialog = value
    },
    async fetchCurrency() {
      try {
        const response = await axios.get('settings/bid')

        this.bid = parseFloat(response.data.data.CNYBRL.bid)
      } catch (error) {
        console.error(error)
      } 
    },
    addToCart(product, mobile) {
      this.wasProductInCart = this.isProductInCart(product)
      this.toggleCartMenu(true)


      if(mobile){
        this.toggleUpdateProductMobile(true)
      }
      this.cart = this.cart.filter(el => el.id != product.id)
      this.cart.push(product)
      localStorage.setItem('cart', JSON.stringify(this.cart))
    },
    removeFromCart(product) {
      this.cart = this.cart.filter(p => p.id !== product.id)
      localStorage.setItem('cart', JSON.stringify(this.cart))
    },

    calculateItemPriceQuantity (standardPrice = '0', quantity, id) {
      let price = 0
      let product_quantity_prices =  JSON.parse(localStorage.getItem(`product_quantity_prices`))?.find(el => el.id == id) ?? {
        id: '',
        prices: [],
      }
      if(product_quantity_prices.prices.length > 0){
        product_quantity_prices.prices[0].begin_num = '0'
      }
      
      if (product_quantity_prices.prices?.length >=2) {
        const foundPrice = product_quantity_prices.prices
          .map(({ begin_num, end_num, price }) => ({
            start: Number(begin_num.replace(/\D/g, '')) || 0,
            end: Number(end_num.replace(/\D/g, '')) || Infinity, 
            price: parseFloat(price),
          }))
          .findLast(({ start, end }) => quantity >= start && quantity <= end)
    
        price = foundPrice?.price || standardPrice
      } else {
        price = parseFloat(standardPrice ?? '0')
      }
      
      return price
    },
    
    handleQuantityChange (variation, item, type) {

      const productIndex = this.cart.findIndex(p => p.id === item.id)
      const productVariationIndex = this.cart[productIndex].variations.findIndex(v => v.color.vid === variation.color.vid)

      if( type == 'increase'){
        this.cart[productIndex].variations[productVariationIndex].quantity = Math.max(1, parseInt(variation.quantity) + 1)
      }else if(type== 'decrease'){
        this.cart[productIndex].variations[productVariationIndex].quantity = Math.max(1, parseInt(variation.quantity) - 1)
      }else{
        this.cart[productIndex].variations[productVariationIndex].quantity = Math.max(1, parseInt(variation.quantity) )
      }
      const total = this.calculateItemPriceQuantity(variation.price, parseInt(this.cart[productIndex].variations[productVariationIndex].quantity), item.id)

      const totalBrl =  Number(total).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })

      this.cart[productIndex].variations[productVariationIndex].price = total
      this.cart[productIndex].variations[productVariationIndex].price_brl = totalBrl

    },
    removeVariationOfProduct(product, variation) {
      if(!variation){
        this.cart =  this.cart.filter(el => el.id != product.id)
      }else{
        const productIndex = this.cart.findIndex(p => p.id === product.id)
        const productVariations = this.cart[productIndex].variations
  
        const variationIndex = productVariations.findIndex(v => v.id === variation.id)
        
        productVariations.splice(variationIndex, 1)
        
        if (productVariations.length === 0) {
          this.cart.splice(productIndex, 1)
        }
        
        localStorage.setItem('cart', JSON.stringify(this.cart))
      }
    },
    isProductInCart(product) {

      return this.cart.some(p => p.id === (product.item_id ?? product.id))
    },
    toggleCartMenu(value) {
      this.isCartMenuOpen = value
    },
    toggleUpdateProductMobile(value) {
      this.confirmUpdateProductMobile = value
    },
    clearCart() {
      this.cart = []
      localStorage.removeItem('cart')
    },
  },
})
