<script setup>
import { useAppAbility } from '@/plugins/casl/useAppAbility'
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { useUserListStore } from '@/views/apps/user/useUserListStore'
import axios from '@axios'
import {
  emailValidator,
  requiredValidator,
} from '@validators'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  isDialogVisible: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['submit', 'update:isDialogVisible', 'setLoggedUser', 'createAccount'])
const authStore = useAuthStore()
const ability = useAppAbility()

const errors = reactive({
  email: '',
  password: '',
})

const refVForm = ref(null)
const email = ref('')
const password = ref('')
const isPasswordVisible = ref(false)
const isLoadingResetEmail = computed(() => authStore.getLoading);
const userListStore = useUserListStore()


const onSubmit = async () => {
  if (refVForm.value) {
    const { valid } = await refVForm.value.validate()
    if (valid) login()
  }
}

const isLoading = ref(false)

const router = useRouter()

const login = () => {
  isLoading.value = true
  axios
    .post('/auth/login', {
      email: email.value,
      password: password.value,
    })
    .then(response => {
      const { token, data } = response.data

      ability.update(data.abilities)
      authStore.setUserData({
        data: data,
        token: token,
        abilities: data.abilities,
      })
      emit('setLoggedUser', response.data)

      isLoading.value = false

      updateDialogVisibility(false)

      if (authStore.getNextRouteName) {
        router.push({ 'name': authStore.getNextRouteName })
        authStore.setNextRouteName(null)
      }
    })
    .catch(error => {
      const { errors: formErrors } = error.response.data

      emit('update:isDialogVisible', true)
      dialogData.title = 'Ops!'
      dialogData.message = 'Email ou senha inválidos'
      dialogData.type = 'error'

      Object.assign(errors, formErrors)
      console.error(error.response.data)
      isLoading.value = false
    })
}

const updateDialogVisibility = val => {
  nextTick(() => {
    refVForm.value?.reset()
    refVForm.value?.resetValidation()
  })
  emit('update:isDialogVisible', val)
}

const noAccount = () => {
  updateDialogVisibility(false)
  emit('createAccount')
}

const showResetPasswordForm = () =>{
  authStore.setWindowRegister(1)
}



const isAuthDialogVisible = computed(() => authStore.getIsAuthDialogVisible)

watch(isAuthDialogVisible, newValue =>{
  if(!newValue){
    authStore.setWindowRegister(0)

  }
}, { deep: true })

const window = ref(computed(() => authStore.getWindowRegister))

const sendEmailResetPassword = async () =>{
  if(email.value != ''){
    await authStore.sendResetEmail(email.value)
  }
}

const isDialogVisible = computed(() => authStore.getIsDialogVisible)
const dialogData = computed(() => authStore.getDialogData)
</script>

<template>
  <VDialog
    :width="$vuetify.display.smAndDown ? 'auto' : 400"
    :model-value="props.isDialogVisible"
    class="notranslate"
    style="z-index: 1000;"

    :fullscreen="$vuetify.display.mobile"
    @update:model-value="updateDialogVisibility"
  >
  <AlertDialog
      class="notranslate"
      :is-visible="isDialogVisible"
      :title="dialogData.title"
      :message="dialogData.message"
      :type="dialogData.type"
      @update:is-visible="authStore.setIsDialogVisible(false)"
    /> 
   
    <!-- Dialog close btn -->
    <DialogCloseBtn
      :style="{'transform': $vuetify.display.mobile && 'translate(-1rem, 1rem)' }"
      @click="updateDialogVisibility(false)"
    />

    <VImg
      src="/images/auth-dialog.webp"
      alt="auth-dialog"
      width="100%"
      :style="{'border-radius': $vuetify.display.mobile ? '0' : '20px 20px 0 0'}"
      height="144px"
      cover
    />
    <VCard
      class="pa-5"
      :style="{'border-radius': '0 0 20px 20px'}"
    >
      <VCardItem class="text-center">
        <VCardTitle class="text-h4 title notranslate">
          {{ window == 0 ? 'Acessar minha conta': 'Recuperar senha' }}  
        </VCardTitle>
      </VCardItem>

      <VCardText class="pt-2">
        <VWindow
          v-model="window"
          :show-arrows="false"
        >
          <VWindowItem>
            <VForm
              ref="refVForm"
              class="mt-6"
              @submit.prevent="onSubmit"
            >
              <VRow>
                <!-- 👉 Email -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppTextField
                    v-model="email"
                    label="Email"
                    type="email"
                    inputmode="email"
                    autofocus
                    :rules="[requiredValidator, emailValidator]"
                    :error-messages="errors.email"
                  />
                </VCol>

                <!-- 👉 Password -->
                <VCol
                  cols="12"
                  class="mb-1"
                >
                  <AppTextField
                    v-model="password"
                    label="Senha"
                    :rules="[requiredValidator]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :error-messages="errors.password"
                    :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  />
                  <VHover>
                    <template #default="{ isHovering, props }">
                      <p
                        v-bind="props"
                        :class="{'text-primary': isHovering}"
                        class="mb-0 pb-0 text-body-2 mt-2 cursor-pointer"
                        @click="showResetPasswordForm"
                      >
                        Esqueci minha senha
                      </p>
                    </template>
                  </VHover>
                </VCol>

                <!-- 👉 Submit -->
                <VCol
                  cols="12"
                  class="d-flex justify-center"
                >
                  <VBtn
                    type="submit"
                    :loading="isLoading"
                    rounded
                    class="text-uppercase w-100"
                  >
                    Entrar
                  </VBtn>
                </VCol>
                <VCol
                  cols="12"
                  class="d-flex justify-center cursor-pointer"
                  @click="noAccount"
                >
                  <p class="footer-text mb-0">
                    <VIcon
                      icon="mdi-lightbulb-outline"
                      size="18"
                    />
                    Não possui uma conta? <span class="font-weight-bold">Criar conta</span>
                  </p>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>
          <VWindowItem elevation="0">
            <VCard
              elevation="10"
              class="pt-0 w-100"
            >
              <VCardText class="d-flex flex-column pt-0 w-100 align-center">
                <p class="mb-3 text-center mt-0">
                  Insira seu email para redefinir sua senha.
                </p>
                <VRow class="w-100 d-flex flex-column align-center">
                  <VCol
                    cols="12"
                    class="px-0"
                  >
                    <AppTextField
                      v-model="email"
                      :disabled="isLoadingResetEmail"
                      label="Email"
                      type="email"
                      inputmode="email"
                      :rules="[requiredValidator, emailValidator]"
                      placeholder="usuario@exemplo.com"
                      :error-messages="errors.email"
                    />
                  </VCol>
                  <VBtn :loading="isLoadingResetEmail" :disabled="isLoadingResetEmail" @click="sendEmailResetPassword">
                    Enviar e-mail 
                  </VBtn>
                </VRow>
              </VCardText>
            </VCard>
          </VWindowItem>
        </VWindow>
        <!-- 👉 Form -->
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.title,
.footer-text {
  font-family: Poppins;
}
</style>
