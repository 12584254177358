<script setup lang="ts">
import LandingPageNavbar from '@/pages/components/landing-page/LandingPageNavbar.vue'
import SectionTitle from '@/pages/components/landing-page/SectionTitle.vue'
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { hexToRgb } from '@layouts/utils'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useTheme } from 'vuetify/lib/framework.mjs'
import HowItWorks from '../components/landing-page/HowItWorks.vue'
import LandingPageDrawer from '../components/landing-page/LandingPageDrawer.vue'
import LandingPageFooter from '../components/landing-page/LandingPageFooter.vue'
import SectionCategories from '../components/landing-page/home/SectionCategories.vue'
import SectionPopularRecomendations from '../components/landing-page/home/SectionPopularRecomendations.vue'
import SectionRecomendations from '../components/landing-page/home/SectionRecomendations.vue'

const { global } = useTheme()

const productsListStore = useProductsListStore()
const router = useRouter()

const searchQuery = computed(() => productsListStore.getSearchQuery)
const categories = computed(() => productsListStore.getCategories)

const searchProducts = () => {

  // Adicionar o termo ao histórico, se não for duplicado
  if (searchQuery.value.keyword) {
    productsListStore.addToSearchHistory(searchQuery.value.keyword)
  }
  productsListStore.setCategorySelectedMlb({ id: '', name: '' })

  searchQuery.value.page = 1
  searchQuery.value.page_size = 16
  searchQuery.value.sort = "default"
  productsListStore.setSearchQuery(searchQuery.value)
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.searchProducts()
  router.push({ name: 'search-products' })
}

const openWhatsapp = () => {
  window.open('https://wa.me/5511910002023', '_blank')
}
</script>

<template>
  <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
    <LandingPageNavbar />
    <LandingPageDrawer />

    <VContainer
      class="landing-page-container"
      
      :class="{'pt-7': !$vuetify.display.mobile, 'pt-15':$vuetify.display.mobile}"
    >
      <VRow
        class="pt-16"
        :class="{'px-0': $vuetify.display.mobile, 'px-7': !$vuetify.display.mobile}"
      >
        <VCol
          cols="12"
          class="pb-0"
        >
          <!-- Hero Card -->
          <VCard
            :style="{ borderRadius: '26px', backgroundColor: '#1A1A1A', minHeight: '411px' }"
            class="d-flex align-center notranslate"
          >
            <VCardText
              class="d-flex pa-0 gap-10 align-center"
              :class="{'flex-column pa-6': $vuetify.display.mobile, 'pr-10': !$vuetify.display.mobile }"
            >
              <img
                src="images/import-header.svg"
                alt="China Fácil"
                :width="$vuetify.display.mobile ? '300' : '450'"
                :class="{'pl-4': !$vuetify.display.mobile}"
              >
              <section
                class="d-flex flex-column gap-7"
                :class="{'pe-16': !$vuetify.display.mobile }"
              >
                <div>
                  <h1 class="title text-h2 text-white">
                    Importação Fácil
                  </h1>
                  <h1 class="title text-h2 text-white">
                    direto da <span :style="{color: '#FF7C7C'}">China</span>
                  </h1>
                </div>
                <p class="subtitle text-white">
                  Faça sua Importação para revenda tão fácil como uma compra na internet! Desde a China até o seu endereço no Brasil!
                </p>
                <VTextField
                  v-model="searchQuery.keyword"
                  hide-details
                  prepend-inner-icon="tabler-search"
                  class="second-search-field notranslate"
                  label="O que deseja importar?"
                  @keyup.enter="searchProducts"
                >
                  <template #append-inner>
                    <VBtn
                      color="primary"
                      rounded="xl"
                      class="m-0"
                      size="small"
                      @click="searchProducts"
                    >
                      Pesquisar
                    </VBtn>
                  </template>
                </VTextField>
              </section>
            </VCardText>
          </VCard>
          <SectionPopularRecomendations v-if="categories.length" />
          <section
            id="categories"
            class="notranslate"
          >
            <SectionTitle
              title="Todas as categorias"
              class="my-5"
            />
            <SectionCategories />
          </section>
          <SectionRecomendations v-if="categories.length" />
        </VCol>
      </VRow>
    </VContainer>
    <HowItWorks />
    <LandingPageFooter />
  </VApp>
</template>

<route lang="yaml">
  meta:
    layout: blank
    action: read
    subject: Auth
    title: Home
</route>

<style lang="scss">
.process__title {
  color: #bb1919;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.title {
  font-family: Poppins, sans-serif !important;
  font-weight: 600 !important;
}

.subtitle {
  font-family: Poppins, sans-serif !important;
  font-weight: 400 !important;
}

.import-search-field {
  border-radius: 28px;
  background-color: #f5f5f5;

  & .v-field__outline__start {
    flex-basis: 20px !important;
    border-radius: 28px 0 0 28px !important;
  }

  & .v-field__outline__end {
    border-radius: 0 28px 28px 0 !important;
  }
}

.second-search-field {
  border-radius: 28px;
  background-color: #222020;
  color: white;

  & .v-field__outline__start {
    flex-basis: 20px !important;
    border-radius: 28px 0 0 28px !important;
  }

  & .v-field__outline__end {
    border-radius: 0 28px 28px 0 !important;
  }
}

.second-search-field .v-field-label {
  color: white !important;
}

.second-search-field .v-field--appended {
  padding-inline-end: 6px;
}

.second-search-field .v-field__append-inner {
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.landing-page-container .layout-page-content {
  padding-block-end: 0;
}
</style>
