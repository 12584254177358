<script setup>
const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: value => ['success', 'error'].includes(value),
  },
  isVisible: {
    type: Boolean,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['update:isVisible'])

const closeDialog = () => {
  emit('update:isVisible', false)
}
</script>

<template>
  <VDialog
    max-width="500"
    :model-value="props.isVisible"
    @update:model-value="closeDialog"
  >
    <VCard class="text-center px-10 py-6">
      <VCardText>
        <!-- Icon based on type -->
        <VBtn
          icon
          variant="outlined"
          :color="props.type === 'success' ? 'success' : 'error'"
          class="my-4"
          style="block-size: 88px; inline-size: 88px; pointer-events: none;"
        >
          <span
            v-if="props.type === 'success'"
            class="text-3xl"
          >
            <VIcon icon="tabler-check" />
          </span>
          <span
            v-else
            class="text-3xl"
          >
            <VIcon icon="tabler-alert-circle" />
          </span>
        </VBtn>

        <!-- Message -->
        <h6 class="text-lg font-weight-medium message">
          {{ props.message }}
        </h6>
      </VCardText>

      <!-- Close Button -->
      <VCardText class="d-flex justify-center">
        <VBtn
          :color="props.type === 'success' ? 'success' : 'error'"
          @click="closeDialog"
        >
          Ok
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style scoped>
.message {
  white-space: pre-wrap; /* ou pre-wrap */
}
</style>
