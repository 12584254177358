import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { canNavigate } from '@layouts/plugins/casl'
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'
import { isUserLoggedIn } from './utils'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ℹ️ We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    {
      path: '/',
      name: 'index',
      component: () => import('@/pages/landing-page/index.vue'),

      // redirect: to => {
      //   const userData = JSON.parse(localStorage.getItem('userData') || '{}')
      //   const userRole = (userData && userData.role) ? userData.role : null

      //   // if (userRole === 'admin')
      //   //   return { name: 'dashboard-admin' }
      //   // if (userRole === 'client')
      //   //   return { name: 'dashboard-clients' }
        
      //   return { name: '/', query: to.query }
      // },
    },
    {
      path: '/champion-products',
      name: 'champion-products',
      meta: { title: 'Campeões de venda' },
      component: () => import('@/pages/landing-page/mercado-livre/index.vue'),
    },
    {
      path: '/favorite-products',
      name: 'favorite-products',
      meta: { title: 'Produtos Favoritos' },
      component: () => import('@/pages/landing-page/favorite-products/index.vue'),
    },
    {
      path: '/search-products',
      name: 'search-products',
      component: () => import('@/pages/landing-page/search-products/index.vue'),
    },
    {
      path: '/cart',
      name: 'cart',
      meta: { title: 'Carrinho' },
      component: () => import('@/pages/landing-page/cart/index.vue'),
    },
    {
      path: '/home/solicitations',
      name: 'home-solicitations',
      meta: { title: 'Solicitações', needLogin: true },
      component: () => import('@/pages/landing-page/solicitations/index.vue'),
    },
    {
      path: '/home/account',
      name: 'home-account',
      meta: { title: 'Minha Conta', needLogin: true },
      component: () => import('@/pages/landing-page/account/index.vue'),
    },
    {
      path: '/pages/user-profile',
      redirect: () => ({ name: 'pages-user-profile-tab', params: { tab: 'profile' } }),
    },
    {
      path: '/pages/account-settings',
      redirect: () => ({ name: 'pages-account-settings-tab', params: { tab: 'account' } }),
    },
    {
      path: '/about',
      name: 'about',
      meta: { title: 'Sobre Nós' },
      component: () => import('@/pages/landing-page/about-us/index.vue'),
    },
    {
      path: '/policies',
      name: 'policies',
      meta: { title: 'Política de Privacidade' },
      component: () => import('@/pages/landing-page/privacy-policy/index.vue'),
    },
    {
      path: '/evento',
      name: 'evento',
      meta: { title: '' },
      component: () => import('@/pages/landing-page/Redirect.vue'),
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      meta: { title: '' },
      component: () => import('@/pages/landing-page/reset-password.vue'),
    },
    
   
    ...setupLayouts(routes),
  ],
})


// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(to => {
  const isLoggedIn = isUserLoggedIn()

  /*
  
    ℹ️ Commented code is legacy code
  
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      // ℹ️ Only add `to` query param if `to` route is not index route
      if (!isLoggedIn)
        return next({ name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } })
  
      // If logged in => not authorized
      return next({ name: 'not-authorized' })
    }
  
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
      next('/')
  
    return next()
  
    */
  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn)
      return '/'
  }

  // else {
  //   if (isLoggedIn)
  //     return { name: 'not-authorized' }
  //   else
  //     return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
  // }
  if( to.meta?.needLogin && !isLoggedIn){
    router.push('/')

    const authStore = useAuthStore()

    authStore.setIsAuthDialogVisible(true) 
  }

})
export default router
