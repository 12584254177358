<script setup>
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { useRoute, useRouter } from 'vue-router'
import SearchHistoryList from './SearchHistoryList.vue'

const router = useRouter()
const productsListStore = useProductsListStore()
const searchQuery = computed(() => productsListStore.getSearchQuery)

const tempImages = ref(computed(() => productsListStore.getImageHistory))

const selectImage = file => {
  productsListStore.resetImageHistory()
  productsListStore.resetProducts()

  const fileReader = new FileReader()
  const { files } = file.target
  if (files && files.length) {
    productsListStore.setCategorySelectedMlb({ id: '', name: '' })
    productsListStore.resetProductsMlb()

    productsListStore.setTempImageName(files[0].name)
    fileReader.readAsDataURL(files[0])
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        productsListStore.setTempImage(fileReader.result) 

        // Adiciona a imagem ao histórico
        productsListStore.addImageToHistory({
          name: files[0].name,
          data: fileReader.result,
          timestamp: new Date().toISOString(),
          index: tempImages.value.length,
        })
      }

      searchQuery.value.keyword = 'Buscar por imagem'
      searchQuery.value.page = 1
      searchQuery.value.page_size = 16
      searchQuery.value.sort = "default"
      productsListStore.setSearchQuery(searchQuery.value)

      const formData = new FormData()

      formData.append('image', refInputEl.value?.files[0])
      productsListStore.searchProductsByImage(formData, 0)
      router.push({ name: 'search-products' })
    }
  }
}

const searchProducts = () => {

  // Adicionar o termo ao histórico, se não for duplicado
  if (searchQuery.value.keyword) {
    productsListStore.addToSearchHistory(searchQuery.value.keyword)
  }
  productsListStore.setCategorySelectedMlb({ id: '', name: '' })

  router.push({ name: 'search-products' })
  searchQuery.value.page = 1
  searchQuery.value.page_size = 16
  searchQuery.value.sort = "default"
  productsListStore.setSearchQuery(searchQuery.value)
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.searchProducts()
}

const route = useRoute()
const refInputEl = ref()
</script>

<template>
  <section class="d-flex w-100 notranslate">
    <VMenu
      v-if="$vuetify.display.mobile"
      transition="slide-y-transition"
      class="notranslate"
    >
      <template #activator="{ props }">
        <VTextField
          v-if="['Home', 'Search', 'Detalhes do produto'].some(term => route.meta.title.includes(term))"
          v-model="searchQuery.keyword"
          hide-details
          v-bind="props"
          prepend-inner-icon="tabler-search"
          class="import-search-field"
          label="O que deseja importar?"
          clearable
          @keyup.enter="searchProducts"
        >
          <template #append-inner>
            <VTooltip
              :model-value="false"
              location="bottom"
            >
              <template #activator="{ props }">
                <VIcon
                  v-bind="props"
                  size="24"
                  class="ma-0 cursor-pointer"
                  icon="tabler-photo-search"
                  @click="refInputEl?.click()"
                />
              </template>
              <span>Pesquise produtos usando uma imagem</span>
            </VTooltip>
          </template>
        </VTextField>
      </template>
      <VSheet class="px-4">
        <SearchHistoryList />
      </VSheet>
    </VMenu>
    <VTextField
      v-else
      v-model="searchQuery.keyword"
      hide-details
      v-bind="props"
      prepend-inner-icon="tabler-search"
      class="import-search-field"
      label="O que deseja importar?"
      clearable
      @keyup.enter="searchProducts"
    >
      <template #append-inner>
        <VTooltip
          :model-value="false"
          location="bottom"
          class="notranslate"
        >
          <template #activator="{ props }">
            <VIcon
              v-bind="props"
              size="24"
              class="ma-0 cursor-pointer"
              icon="tabler-photo-search"
              @click="refInputEl?.click()"
            />
          </template>
          <span>Pesquise produtos usando uma imagem</span>
        </VTooltip>
      </template>
    </VTextField>
    <input
      ref="refInputEl"
      type="file"
      name="file"
      accept=".jpeg,.png,.jpg,GIF, .WEBP"
      hidden
      @input="selectImage"
    >
  </section>
</template>
