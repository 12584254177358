<script setup>
import { useAppAbility } from '@/plugins/casl/useAppAbility'
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import axios from '@axios'
import {
  emailValidator,
  requiredValidator,
} from '@validators'
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'


const props = defineProps({
  isDialogVisible: {
    type: Boolean,
    required: true,
  },
  startWindow: {
    type: Number,
    default: 0,
  },
})

const emit = defineEmits(['submit', 'update:isDialogVisible', 'setLoggedUser', 'login' ])

const authStore = useAuthStore()

const ability = useAppAbility()

const errors = reactive({
  email: '',
  password: '',
  phone: '',
})

const refForm = ref()
const refFormChangeNumber = ref()
const name = ref('')
const email = ref('')
const phone = ref('')
const password = ref('')
const role = ref('lead')
const isPasswordVisible = ref(false)
const employees = ref(null)
const monthlyBilling = ref(null)

const rangeEmployees = [
  '1-5',
  '6-10',
  '11-50',
  '+ 50',
]

const rangeMonthlyBilling = [
  'R$ 0 - R$ 10.000',
  'R$ 10.001 - R$ 50.000',
  'R$ 50.001 - R$ 100.000',
  'R$ 100.001 - R$ 250.000',
  'R$ 250.001 - R$ 500.000',
  'R$ 500.000',
]

const leadPermissions = [
  {
    "action": "read",
    "subject": "Solicitations",
  },
  {
    "action": "read",
    "subject": "General",
  },
  {
    "action": "read",
    "subject": "UserProfile",
  },
  {
    "action": "read",
    "subject": "Logout",
  },
  {
    "action": "read",
    "subject": "Auth",
  },
  {
    "action": "read",
    "subject": "ClientDashboard",
  },
]

const onSubmit = async () => {

  refForm.value?.validate().then(async ({ valid }) => {

    if (valid) {
      await register()
    }
  })
}

const sendCodeVerification = async () =>{

  const result = await authStore.sendCodeVerification(phone.value)

  startTimer()
  if( result.status != 'success'){
    if(result.error_code == 'otp_already_sent'){
      timeLeft.value = 0
    }
    authStore.setIsDialogData({
      title: 'Erro',
      message: result?.message ?? 'Não foi possível validar seu telefone. Tente novamente mais tarde.',
      type: 'error',
    })
    authStore.setIsDialogVisible(true)
  }
}

const isLoading = ref(false)
const userData = ref(null)

const register = async () => {
  isLoading.value = true

  await axios
    .post('/users', {
      name: name.value,
      phone: phone.value,
      email: email.value,
      role: role.value,
      clients: null,
      password: password.value,
      password_confirmation: password.value,
      abilities: leadPermissions,
      employees: employees.value,
      monthly_billing: monthlyBilling.value,
    })
    .then(response => {
      userData.value = response.data

      const { token, data } = response.data
  
      emit('setLoggedUser', response.data)

      ability.update(data.abilities)
      authStore.setUserData({
        data: data,
        token: token,
        abilities: data.abilities,
      })
      isLoading.value = false
      sendCodeVerification()
      authStore.setWindowRegister(1)

    })
    .catch(error => {
      const { errors: formErrors } = error.response.data

      console.log(error)
      emit('update:isDialogVisible', true)

      authStore.setIsDialogData({
        title: 'Ops!',
        message: error.response.data.message ?? 'Não foi possível criar sua conta. Tente novamente mais tarde.',
        type: 'error',
      })
      authStore.setIsDialogVisible(true)
      Object.assign(errors, formErrors)
      console.error(error.response.data)
      isLoading.value = false
    })
}

const updateDialogVisibility = val => {
  nextTick(() => {
    refForm.value?.reset()
    refForm.value?.resetValidation()
    authStore.setWindowRegister(0)
    otpCode.value = null
    authStore.setIsAuthDialogVisible(false)
  })
  emit('update:isDialogVisible', val)
}

const haveAccount = () => {
  updateDialogVisibility(false)
  emit('login')
}

const localUser = computed(() => authStore.getUserData)

const window = ref(computed(() => authStore.getWindowRegister))

watch(window, async newValue =>{
  if(newValue === 1){
    if(localUser.value.phone){
      await authStore.sendCodeVerification(localUser.value.phone)
    }else{
      changePhoneDisplay.value = true
    }

  }
})


const otpCode = ref('')

const validateOptCode = async () => {

  if(changePhoneDisplay.value){
    await  authStore.sendCodeVerification(phone.value)
    changePhoneDisplay.value= false
  }else{
    emit('update:isDialogVisible', true)
  
    if(otpCode.value.length == 6){
      isLoading.value = true
      
      const result =  await authStore.validateOtpCode(otpCode.value, phone.value)
      
      if(result?.status == 'success'){
  
        setTimeout(() =>{
          updateDialogVisibility(false)
        }, 2000)

      }else{
        authStore.setIsDialogData({
          title: 'Erro',
          message: result?.message ?? 'Não foi possível validar seu telefone. Tente novamente mais tarde.',
          type: 'error',
        })
        authStore.setIsDialogVisible(true)
      }
      isLoading.value = false
  
  
    }

  }
}

const isDialogVisible = computed(() => authStore.getIsDialogVisible)
const dialogData = computed(() => authStore.getDialogData)
const changePhoneDisplay = ref(false)
 
const phoneValidator = value => {
  const cleanedValue = value.replace(/\D/g, '') // Remove caracteres não numéricos
  
  if (cleanedValue.length !== 10 && cleanedValue.length !== 11) {
    return 'Número de telefone incompleto'
  }

  // Expressão regular para validar DDDs válidos e formatos de telefone brasileiros
  const phoneRegex = /^(1[1-9]|2[1-9]|3[1-9]|4[1-9]|5[1-9]|6[1-9]|7[1-9]|8[1-9]|9[1-9])\d{8,9}$/
  
  return phoneRegex.test(cleanedValue) ? true : 'Número de telefone inválido'
}

const duration = 60 // 1 minuto (60 segundos)
const timeLeft = ref(duration)
const percentage = ref(100)
let interval = null

const startTimer = () => {
  if (!interval) {
    interval = setInterval(() => {
      if (timeLeft.value > 0) {
        timeLeft.value--
        percentage.value = Math.ceil((timeLeft.value * 100) / duration)
      } else {
        clearInterval(interval)
        interval = null
      }
    }, 1000)
  }
}

const resetTimer = () => {
  clearInterval(interval)
  interval = null
  timeLeft.value = duration
  percentage.value = 100
}


onUnmounted(() => {
  clearInterval(interval)
})

onMounted(() =>{
  startTimer()

  const localUser = JSON.parse(localStorage.getItem('userData'))

  if(localUser?.phone){
    phone.value = localUser.phone
  }
})

const resendPhoneCode = async () =>{

  resetTimer()
  startTimer()

  const result = await authStore.reSendCodeVerification(phone.value)
  if(result?.status != 'success'){
  
    authStore.setIsDialogData({
      title: 'Erro',
      message: result?.message ?? 'Não foi enviar o código ao seu telefone. Tente novamente mais tarde.',
      type: 'error',
    })
    authStore.setIsDialogVisible(true)
  }
}

const validateFullName = value => {
  if (!value) return "O nome é obrigatório."
  const nameParts = value.trim().split(" ")
  
  return nameParts.length > 1 ? true : "Digite o nome completo."
}

const confirmNewNumber = async  () =>{
  refFormChangeNumber.value?.validate().then(async ({ valid }) => {

    if (valid) {
      if(localUser.value.id){
        authStore.updateUserPhone(localUser.value.id, phone.value)
      }
      validateOptCode()
    }
  })

}
</script>

<template>
  <VDialog
    :width="$vuetify.display.smAndDown ? 'auto' : 400"
    :model-value="props.isDialogVisible"
    class="notranslate"

    :fullscreen="$vuetify.display.mobile"
    @update:model-value="updateDialogVisibility"
  >
    <AlertDialog
      class="notranslate"
      :is-visible="isDialogVisible"
      :title="dialogData.title"
      :message="dialogData.message"
      :type="dialogData.type"
      @update:is-visible="authStore.setIsDialogVisible(false)"
    /> 
   
    <!-- Dialog close btn -->
    <DialogCloseBtn
      :style="{'transform': $vuetify.display.mobile && 'translate(-1rem, 1rem)' }"
      @click="updateDialogVisibility(false)"
    />


    <VImg
      src="/images/register-dialog.webp"
      alt="auth-dialog"
      width="100%"
      :style="{'border-radius': '20px 20px 0 0'}"
      height="144px"
      cover
    />
    <VCard
      class="pa-5"
      :style="{'border-radius': '0 0 20px 20px'}"
    >
      <VCardItem class="text-center">
        <VCardTitle class="text-h4 text-wrap title">
          {{ window == 0 ? 'Crie sua conta ' : 'Verificação de telefone' }}  
        </VCardTitle>
        <p
          v-if="window == 1 && !changePhoneDisplay"
          class="text-center text-body-1 mb-0 text-primary"
        >
          Por favor, digite o código enviado ao seu telefone <span class="text-black">{{ phone }}</span> via SMS
        </p>
        <p
          v-if="changePhoneDisplay"

          class="text-center text-body-1 mb-0 text-primary"
        >
          Insira o novo número de telefone.
        </p>
      </VCardItem>

      <VCardText>
        <VWindow
          v-model="window"
          :show-arrows="false"
        >
          <VWindowItem>
            <VForm
              ref="refForm"
              class="mt-6"
              @submit.prevent="onSubmit"
            >
              <VRow>
                <!-- 👉 Name -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppTextField
                    v-model="name"
                    required
                    label="Nome"
                    type="text"
                    inputmode="text"
                    autofocus
                    :rules="[requiredValidator,validateFullName]"
                  />
                </VCol>

                <!-- 👉 Employees -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppSelect
                    v-model="employees"
                    label="Número de funcionários"
                    :items="rangeEmployees"
                    :rules="[requiredValidator]"
                  />
                </VCol>

                <!-- 👉 Monthly Billing -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppSelect
                    v-model="monthlyBilling"
                    label="Faturamento mensal"
                    :items="rangeMonthlyBilling"
                    :rules="[requiredValidator]"
                  />
                </VCol>
    
                <!-- 👉 Email -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppTextField
                    v-model="email"
                    label="Email"
                    type="email"
                    inputmode="email"
                    :rules="[requiredValidator, emailValidator]"
                    placeholder="usuario@exemplo.com"
                    :error-messages="errors.email"
                  />
                </VCol>
    
                <!-- 👉 Phone -->
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <AppTextField
                    v-model="phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    label="Telefone"
                    type="phone"
                    inputmode="phone"
                    :rules="[requiredValidator,phoneValidator]"
                    placeholder="(__) _____-____"
                    :error-messages="errors.phone"
                  />
                </VCol>
    
                <!-- 👉 Password -->
                <VCol
                  cols="12"
                  class="mb-1"
                >
                  <AppTextField
                    v-model="password"
                    label="Senha"
                    :rules="[requiredValidator]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :error-messages="errors.password"
                    :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  />
                </VCol>
    
                <!-- 👉 Submit -->
                <VCol
                  cols="12"
                  class="d-flex justify-center"
                >
                  <VBtn
                    type="submit"
                    :loading="isLoading"
                    rounded
                    class="text-uppercase w-100"
                  >
                    Criar minha conta
                  </VBtn>
                </VCol>
                <VCol
                  cols="12"
                  class="d-flex justify-center cursor-pointer"
                  @click="haveAccount"
                >
                  <p class="footer-text mb-0">
                    <VIcon
                      icon="mdi-lightbulb-outline"
                      size="18"
                    />
                    Já possui uma conta? <span class="font-weight-bold">Acessar conta</span>
                  </p>
                </VCol>
              </VRow>
            </VForm>
          </VWindowItem>
          <VWindowItem elevation="0">
            <VCard
              elevation="10"
              class="pt-0"
            >
              <VCardText class="d-flex flex-column pt-0 align-center">
                <VAvatar
                  v-if="!changePhoneDisplay"

                  color="primary"
                  size="80"
                >
                  <VIcon
                    icon="tabler-password-mobile-phone"
                    size="50"
                  />
                </VAvatar>
                

                <VOtpInput
                  v-if="!changePhoneDisplay"

                  v-model="otpCode"
                  :loading="isLoading"
                  :disabled="isLoading"
                  :error="otpCode != null && otpCode.length <6"
                  variant="solo"
                />
                
                <p
                  v-if="!changePhoneDisplay"
                  class="text-body-1 "
                >
                  Deseja trocar o número? <span class="font-weight-bold"><span
                    class="text-primary"
                    @click="changePhoneDisplay = true"
                  >Clique aqui</span></span>
                </p> 
                <VBtn
                  v-if="timeLeft == 0 && !changePhoneDisplay"
                  variant="text"
                  @click="resendPhoneCode"
                >
                  Reenviar código
                </VBtn>
                <p v-if=" timeLeft > 0 && !changePhoneDisplay">
                  Reenvie o código em {{ timeLeft }}s
                </p>
                <VRow class="w-100 mb-5">
                  <VCol>
                    <VForm
                      ref="refFormChangeNumber"
                      class="mt-6"
                    >
                      <AppTextField
                        v-if="changePhoneDisplay"
                        v-model="phone"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        label="Telefone"
                        type="phone"
                        inputmode="phone"
                        :rules="[requiredValidator,phoneValidator]"
                        placeholder="(__) _____-____"
                        :error-messages="errors.phone"
                      />
                    </VForm>
                  </VCol>
                </VRow>
               
                <VBtn
                  v-if="!changePhoneDisplay"
                  :disabled="isLoading || (!otpCode || otpCode.length < 6)"
                  @click="validateOptCode"
                >
                  Validar
                </VBtn>

                <VBtn
                  v-else
                  :disabled=" !phone"
                  @click="confirmNewNumber"
                >
                  Confirmar número
                </VBtn>
              </VCardText>
            </VCard>
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.title,
.footer-text {
  font-family: Poppins;
}

.otp-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-block-start: 20px;
}

.otp-input {
  block-size: 56px;
  font-size: 24px;
  inline-size: 56px;
  text-align: center;
}
</style>
