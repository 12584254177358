<script setup>
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import { initialAbility } from '@/plugins/casl/ability'
import { useAppAbility } from '@/plugins/casl/useAppAbility'
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { useSettingsStore } from '@/views/apps/settings/useSettingsStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { VNavigationDrawer } from 'vuetify/lib/components/index.mjs'

const settingsStore = useSettingsStore()
const router = useRouter()

const isDrawerVisible = computed({
  get: () => settingsStore.getIsDrawerVisible,
  set: value => settingsStore.handleIsDrawerVisible(value),
})

const ability = useAppAbility()

const authStore = useAuthStore()
const accessToken = computed(() => authStore.getAccessToken)
const userData = computed(() => authStore.getUserData)

const drawerItems = computed(() => settingsStore.getDrawerItems)
const accountItems = computed(() => settingsStore.getDrawerAccountItems)

const goTo = item => {
  if (item.to.name === 'champion-products') {
    window.location.href = '/champion-products'
    
    return
  }
  settingsStore.handleIsDrawerVisible(false)
  router.push(item.to)
}

const auth = () => {
  settingsStore.handleIsDrawerVisible(false)
  authStore.setIsAuthDialogVisible(true)
}

const register = () => {
  settingsStore.handleIsDrawerVisible(false)
  authStore.setIsRegisterDialogVisible(true)
}

const confirmDialog = ref(null)
const isConfirmDialogVisible = ref(false)

const handleLogout = () => {
  isConfirmDialogVisible.value = true
}

const logout = () => {
  authStore.logout()
  ability.update(initialAbility)
}
</script>

<template>
  <section class="notranslate">
    <VNavigationDrawer
      v-model="isDrawerVisible"
      width="500"
      :style="{position: 'fixed!important'}"
    >
      <VCard
        color="secondary"
        variant="tonal"
        class="py-2"
      >
        <VCardTitle class="d-flex justify-space-between align-center pb-5">
          <span class="text-h3 text-wrap">Bem vindo!</span>
          <VIcon
            class="font-weight-bold"
            @click="settingsStore.handleIsDrawerVisible(false)"
          >
            tabler-x
          </VIcon>
        </VCardTitle>
        <section
          v-if="accessToken"
          class="d-flex justify-space-between align-center px-4 pb-3 h-1"
        >
          <section class="d-flex justify-start h-1">
            <VBadge
              dot
              bordered
              location="bottom right"
              offset-x="4"
              offset-y="4"
              color="success"
              class="me-3"
            >
              <VAvatar
                class="cursor-pointer"
                :color="!(userData && userData.avatar) ? 'primary' : undefined"
                :variant="!(userData && userData.avatar) ? 'tonal' : undefined"
                :class="{'border border-2 border-light': userData.avatar}"
                size="45"
              >
                <VImg
                  v-if="userData.avatar"
                  :src="`/avatars/${userData.avatar}`"
                />
                <VIcon
                  v-else
                  icon="tabler-user"
                />
              </VAvatar>
            </VBadge>
                  
            <div style="display: block;">
              <p class="my-0 font-weight-black text-h5">
                {{ userData.name }}
              </p>
              <p class="my-0 text-body-1 font-weight-light">
                {{ userData.email }}
              </p>
            </div>
          </section>

          <VIcon
            icon="tabler-logout"
            class="pe-1"
            @click="handleLogout"
          />
          <!--
            <pre>
            {{ accountItems }}
            </pre> 
          -->
        </section>
        <VCardText
          v-else
          class="px-4 pb-3"
        >
          <p class="text-h5 text-wrap">
            Acesse sua conta para ter <span class="font-weight-bold">uma experiência personalizada.</span>
          </p>
          <VRow>
            <VCol cols="6">
              <VBtn
                rounded
                block
                @click="auth"
              >
                Entrar
              </VBtn>
            </VCol>
            <VCol cols="6">
              <VBtn
                rounded
                block
                color="primary"
                variant="tonal"
                :style="{'text-transform': 'none!important'}"
                @click="register"
              >
                Crie sua conta
              </VBtn>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
      <VList>
        <VListItem
          v-for="(item, i) in accountItems"
          v-if="accessToken"
          :key="i"
          :disabled="item.disabled"
          slim
          exact
          color="transparent"
          @click.stop="goTo(item)"
        >
          <template #prepend>
            <VIcon
              :icon="item.icon"
              color="black"
            />
          </template>
          <div class="d-flex align-center">
            <VListItemTitle class="notranslate">
              {{ item.text }}
            </VListItemTitle>
            <VChip 
              v-if="item.disabled && !item.needLogin"
              class="mx-2"
              color="primary"
              variant="outlined"
            >
              <VIcon
                icon="mdi-clock-outline"
                start
                class="notranslate"
                size="16"
              />
              Em Breve
            </VChip>
          </div>
        </VListItem>
        <VDivider v-if="accessToken" />
        <VListItem
          v-for="(item, i) in drawerItems"
          :key="i"
          :disabled="item.disabled || (item.needLogin && !accessToken)"
          exact
          color="transparent"
          @click.stop="goTo(item)"
        >
          <template #prepend>
            <VIcon
              :icon="item.icon"
              color="black"
            />
          </template>
          <div class="d-flex align-center">
            <VListItemTitle class="notranslate">
              {{ item.text }}
            </VListItemTitle>
            <VChip 
              v-if="item.disabled"
              class="mx-2"
              color="primary"
              variant="outlined"
            >
              <VIcon
                icon="mdi-clock-outline"
                start
                class="notranslate"
                size="16"
              />
              Em Breve
            </VChip>
          </div>
        </VListItem>
      </VList>
    </VNavigationDrawer>
    <ConfirmDialog
      :is-dialog-visible="isConfirmDialogVisible"
      :confirm-dialog="confirmDialog"
      confirmation-question="Deseja realmente finalizar a sessão?"
      confirm-button-title="Sair"
      hide-confirm
      hide-cancel
      @update:is-dialog-visible="isConfirmDialogVisible = $event"
      @confirm="logout"
    />
  </section>
</template>
