import { resolveVuetifyTheme } from '@core/utils/vuetify'
import { themeConfig } from '@themeConfig'
import colors from 'vuetify/util/colors'

export const staticPrimaryColor = '#BB1717'

const theme = {
  defaultTheme: resolveVuetifyTheme(),
  themes: {
    customProperties: true,
    light: {
      dark: false,
      colors: {
        'primary': localStorage.getItem(`${themeConfig.app.title}-lightThemePrimaryColor`) || staticPrimaryColor,
        'on-primary': '#fff',
        'secondary': '#A8AAAE',
        'on-secondary': '#fff',
        'success': '#28C76F',
        'on-success': '#fff',
        'blue': colors.blue.base,
        'black': '#000',
        'green': colors.green.base,
        'info': '#00CFE8',
        'on-info': '#fff',
        'warning': '#FF9F43',
        'on-warning': '#fff',
        'error': '#EA5455',
        'background': '#F8F7FA',
        'on-background': '#2F2B3D',
        'on-surface': '#2F2B3D',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        'perfect-scrollbar-thumb': '#DBDADE',
        'skin-bordered-background': '#fff',
        'skin-bordered-surface': '#fff',
      },
      variables: {
        'code-color': '#d400ff',
        'overlay-scrim-background': '#4C4E64',
        'tooltip-background': '#BB1717',
        'overlay-scrim-opacity': 0.5,
        'hover-opacity': 0.04,
        'focus-opacity': 0.12,
        'selected-opacity': 0.06,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'disabled-opacity': 0.42,
        'border-color': '#2F2B3D',
        'border-opacity': 0.16,
        'high-emphasis-opacity': 0.78,
        'medium-emphasis-opacity': 0.68,
        'switch-opacity': 0.2,
        'switch-disabled-track-opacity': 0.3,
        'switch-disabled-thumb-opacity': 0.4,
        'switch-checked-disabled-opacity': 0.3,

        // Shadows
        'shadow-key-umbra-color': '#2F2B3D',
      },
    },
    dark: {
      dark: true,
      colors: {
        'primary': localStorage.getItem(`${themeConfig.app.title}-darkThemePrimaryColor`) || staticPrimaryColor,
        'on-primary': '#FFFFFF',
        'secondary': '#808080',
        'on-secondary': '#FFFFFF',
        'success': '#28C76F',
        'on-success': '#FFFFFF',
        'info': '#00CFE8',
        'on-info': '#FFFFFF',
        'warning': '#FF9F43',
        'on-warning': '#FFFFFF',
        'error': '#EA5455',
        'background': '#1A1B1E',
        'on-background': '#E0E0E0',
        'surface': '#2B2C2F',
        'on-surface': '#E0E0E0',
        'grey-50': '#1E1F23',
        'grey-100': '#2A2B30',
        'grey-200': '#33343A',
        'grey-300': '#44454D',
        'grey-400': '#565860',
        'grey-500': '#6B6D75',
        'grey-600': '#858891',
        'grey-700': '#A0A3AD',
        'grey-800': '#BFC2CD',
        'grey-900': '#DDE0E9',
        'perfect-scrollbar-thumb': '#3A3B42',
        'skin-bordered-background': '#242529',
        'skin-bordered-surface': '#242529',
      },
      variables: {
        'code-color': '#FF6EC7',
        'overlay-scrim-background': '#121315',
        'tooltip-background': '#3A3B42',
        'overlay-scrim-opacity': 0.5,
        'hover-opacity': 0.08,
        'focus-opacity': 0.16,
        'selected-opacity': 0.1,
        'activated-opacity': 0.18,
        'pressed-opacity': 0.16,
        'dragged-opacity': 0.12,
        'disabled-opacity': 0.4,
        'border-color': '#44454D',
        'border-opacity': 0.2,
        'high-emphasis-opacity': 0.85,
        'medium-emphasis-opacity': 0.65,
        'switch-opacity': 0.35,
        'switch-disabled-track-opacity': 0.3,
        'switch-disabled-thumb-opacity': 0.5,
        'switch-checked-disabled-opacity': 0.25,
    
        // Shadows
        'shadow-key-umbra-color': '#0E0F13',
      },
    },
  },
}

export default theme
