import axios from '@axios'
import { defineStore } from 'pinia'

export const useUserListStore = defineStore('UserListStore', {
  state: () => ({
    users: [],
    isLoading: false,
    user: {},
    userData: {},
    totalPage: 0,
    totalUsers: 0,
    statistics: {
      totalLeads: 0,
      totalClients: 0,
      totalSolicitations: 0,
      totalUsers: 0,
    },
    currentPage: 1,
    itemsPerPage: 15,
    isDialogVisible: false,
    dialogData: {},
    isActiveUserSidebarContent: false,
    options: {
      page: 1,
      itemsPerPage: 15,
      sortBy: [],
      groupBy: [],
    },
    searchQuery: '',
    settingTab: localStorage.getItem('settingTab') || '0' 
  }),
  getters: {
    // 👉 Getters
    getUsers: state => state.users,
    getIsLoading: state => state.isLoading,
    getUser: state => state.user,
    getAdmins: state => state.users.filter(user => user.role === 'admin'),
    getTotalAdmins: state => state.users.filter(user => user.role === 'admin').length,
    getIsActiveUserSidebarContent: state => state.isActiveUserSidebarContent,
    getUserData: state => state.userData,
    getTotalPage: state => state.totalPage,
    getStatistics: state => state.statistics,
    getTotalUsers: state => state.totalUsers ?? 0,
    getCurrentPage: state => state.currentPage,
    getItemsPerPage: state => state.itemsPerPage,
    getIsDialogVisible: state => state.isDialogVisible,
    getDialogData: state => state.dialogData,
    getSettingTab: state => state.settingTab,

  },
  actions: {
    setSettingTab(valor) {
      this.settingTab = valor
      localStorage.setItem('settingTab', valor) 
    },
    setOptions(options){
      options.search = undefined
      this.options = options
    },
    setSearchQuery(search){
      this.searchQuery = search
    },
    fetchStatistics() {
      this.isLoading = true
      let url = `get-statistics-admin-dashboard`
      
      axios.get(url)
        .then(response => {
          this.statistics = response.data.data
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
    },
    fetchUsers(role = null, phoneValidated = null) {
      this.isLoading = true
      let url = `users?&items_per_page=${this.options.itemsPerPage}&page=${this.options.page}`
      if (role) {
        url += `&role=${role}`
      }
      if(phoneValidated != null){
        url += `&phone_verified=${phoneValidated}`
      }
      if(this.searchQuery){
        url += `&search=${this.searchQuery}`
      }
      if(this.options.sortBy.length > 0){
        const sortOptions = this.options.sortBy[0]
        if(sortOptions.key == 'user'){
          sortOptions.key = 'name'
        }
        url += `&order=${sortOptions.order}&order-key=${sortOptions.key}`
      }
      axios.get(url)
        .then(response => {
          this.users = response.data.data.data
          this.totalPage = response.data.data.totalPage
          this.totalUsers = response.data.data.total
          this.currentPage = response.data.data.current_page
          this.itemsPerPage = response.data.data.per_page
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
    },

    addUser(userData) {
      this.isLoading = true
      axios.post('/users', {
        ...userData,
      }).then(response => {
        this.users.push(response.data.data)
        this.isDialogVisible = true
        this.dialogData = {
          title: 'Sucesso',
          message: 'Usuário cadastrado com sucesso!',
          type: 'success',
        }
        this.isLoading = false
      })
        .catch(error => {
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Ops!',
            message: 'Erro ao cadastrar usuário, tente novamente!',
            type: 'error',
          }
          console.error(error)
          this.isLoading = false
        })
    },

    // 👉 fetch single user
    async  fetchUser(id) {
      await  axios.get(`/users/${id}`)
        .then(response => {
          this.user = response.data.data
        })
        .catch(error => {
          console.error(error)
        })
    },


    // 👉 Update User
    updateUser(id, userData) {
      this.isLoading = true
      axios.patch(`/users/${id}`, {
        ...userData,
      })
        .then(response => {
          this.user = response.data.data
          this.users = this.users.map(user => {
            if (user.id === id) {
              return response.data.data
            }
            
            return user
          })
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Sucesso!',
            message: 'Usuário atualizado com sucesso!',
            type: 'success',
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Ops!',
            message: 'Erro ao atualizar usuário, tente novamente!',
            type: 'error',
          }
          console.error(error)
          this.isLoading = false
        })
    },

    // 👉 Update User Avatar
    updateUserAvatar(id, formData) {
      axios.post(`/users/${id}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.user = response.data.data

          const localUser = JSON.parse(localStorage.getItem('userData'))
          if (localUser.id === this.user.id) {
            this.userData = this.user
            localStorage.setItem('userData', JSON.stringify(this.user))
          }
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Sucesso!',
            message: 'Usuário atualizado com sucesso!',
            type: 'success',
          }
        })
        .catch(error => {
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Ops!',
            message: 'Erro ao atualizar usuário, tente novamente!',
            type: 'error',
          }
          console.error(error)
        })
    },

    // 👉 Delete User
    deleteUser(id) {
      this.isLoading = true
      axios.delete(`/users/${id}`)
        .then(response => {
          this.users = this.users.filter(user => user.id !== id)
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
      
    },
    setUser (user) {
      this.user = user
    },
    setUserData(userData) {
      this.userData = userData
    },
    setIsDialogVisible(value) {
      this.isDialogVisible = value
    },
    setIsActiveUserSidebarContent(value) {
      this.isActiveUserSidebarContent = value
    },
  },
})
