<script setup>
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { computed, ref } from 'vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

const props = defineProps({
  isDialogVisible: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['submit', 'update:isDialogVisible'])

const productsListStore = useProductsListStore()

const tempImages = ref(computed(() => productsListStore.getImageHistory))
const newImage = ref(null)

const updateDialogVisibility = val => {
  emit('update:isDialogVisible', val)
}

const onChange = ({ canvas }) => {
  if (canvas) {
    newImage.value = canvas.toDataURL('image/png') // Garante o formato PNG
  }
}

// Função para converter Base64 em Blob
const dataURLToBlob = dataURL => {
  const byteString = atob(dataURL.split(',')[1])
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  
  return new Blob([ab], { type: mimeString })
}

const setImage = async () => {
  if (!newImage.value) return

  const blob = dataURLToBlob(newImage.value) // Converte Base64 para Blob
  const formData = new FormData()

  formData.append('image', blob, 'cropped-image.png') // Adiciona ao FormData

  // Armazena a imagem recortada no store
  productsListStore.setTempImage(newImage.value)

  let lastIdx = tempImages.value.length - 1

  productsListStore.addImageToHistory({
    name: `Imagem Cortada - ${new Date().toLocaleString()}`,
    data: newImage.value,
    timestamp: new Date().toISOString(),
    index: lastIdx += 1,
  })

  // Atualiza a query de busca
  productsListStore.setSearchQuery({
    keyword: 'Buscar por imagem',
    page: 1,
    page_size: 16,
    sort: 'default',
  })

  // Envia o formData com a imagem cropada
  productsListStore.searchProductsByImage(formData, lastIdx++)

  emit('update:isDialogVisible', false)
}
</script>

<template>
  <VDialog
    :model-value="props.isDialogVisible"
    :width="$vuetify.display.smAndDown ? 'auto' : 580"
    class="notranslate"
    @update:model-value="updateDialogVisibility"
  >
    <DialogCloseBtn @click="updateDialogVisibility(false)" />

    <VCard class="refer-and-earn-dialog">
      <VCardText class="px-5 px-sm-16 pt-7 pb-10 d-flex flex-column align-center">
        <h5 class="text-h5 font-weight-bold text-center mb-3">
          Selecionar produto na imagem
        </h5>
        <p class="text-sm-body-1 text-center pb-7">
          Refine sua busca selecionando apenas o que deseja encontrar 
        </p>

        <Cropper
          class="cropper"
          :src="tempImages[0].data"
          @change="onChange"
        />

        <VForm @submit.prevent="setImage">
          <VCol
            cols="12"
            class="text-center"
          >
            <VBtn
              color="secondary"
              variant="tonal"
              rounded
              @click="updateDialogVisibility(false)"
            >
              Cancelar
            </VBtn>
            <VBtn
              class="ms-3"
              type="submit"
              rounded
            >
              Confirmar
            </VBtn>
          </VCol>
        </VForm>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss">
.cropper {
  width: 400px;
}
</style>
