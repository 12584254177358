<script lang="ts" setup>
import { reactive } from 'vue'
import ProcessCard from './home/ProcessCard.vue'

const processItems = reactive([
  { title: 'Fornecedores Ideais', text: 'Identificação precisa do melhor parceiro.' },
  { title: 'Negociação e Custos', text: 'Gerenciamento completo dos custos e desembaraço.' },
  { title: 'Impostos e Pagamentos', text: 'Transparência total no cálculo de impostos.' },
  { title: 'Frete Internacional', text: 'Seleção do frete mais vantajoso.' },
  { title: 'Embarque e Entrega', text: 'Coordenação do embarque até a chegada na sua porta.' },
  { title: 'Documentação', text: 'Assessoria completa em documentação.' },
])
</script>

<template>
  <VSheet id="how-it-works">
    <VContainer>
      <VCard
        class="pt-4 notranslate"
        :class="{'px-14': !$vuetify.display.mobile, 'px-0': $vuetify.display.mobile}"
        flat
      >
        <p class="process__title">
          A China Fácil atua de ponta-a-ponta em todas as etapas
        </p>

        <VCardText class="px-0">
          <VRow>
            <VCol
              cols="12"
              md="4"
              lg="4"
              class="pa-2"
            >
              <img
                style="max-width: 100%;"
                src="/images/process.webp"
              >
            </VCol>

            <VCol
              cols="12"
              md="8"
              lg="8"
            >
              <VRow>
                <VCol
                  v-for="(item, index) in processItems"
                  :key="index"
                  cols="12"
                  md="4"
                  lg="4"
                  class="pa-2"
                >
                  <ProcessCard
                    :title="item.title"
                    :text="item.text"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VContainer>
  </VSheet>  
</template>
