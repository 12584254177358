<script setup>
const openWhatsapp = () => {
  window.open('https://wa.me/5511910002023', '_blank')
}
</script>

<template>
  <VScaleTransition
    style="transform-origin: center;"
    class="whats-icon d-print-none"
  >
    <img
      src="/images/wpp_button.svg"
      alt="Entrar em contato com a China Fácil"
      max-width="80"
      class="cursor-pointer"
      @click="openWhatsapp"
    >
  </VScaleTransition>
</template>

<style lang="scss">
.whats-icon {
  position: fixed !important;

  // To keep button on top of v-layout. E.g. Email app
  z-index: 999;
  inset-block-end: 3%;
  inset-inline-end: 20px;
}
</style>
