import axios from '@axios'
import { defineStore } from 'pinia'

export const useProductsListStore = defineStore('ProductsListStore', {
  state: () => ({
    products: [],
    productsMlb: [],
    categories: [],
    categorySelectedMlb: { id: '', name: '' },
    product: {},
    productDetails: {},
    detailPhotos: {},
    isLoading: false,
    isLoadingMore: false,
    isLoadingOptions: true,
    isLoadingDetails: true,
    tempImage: null,
    tempImageName: null,
    formData: null,
    searchHistory: localStorage.getItem('searchHistory') ? JSON.parse(localStorage.getItem('searchHistory')) : [],
    suggestedProducts: localStorage.getItem('suggestedProducts') ? JSON.parse(localStorage.getItem('suggestedProducts')) : [],
    searchQuery: {
      page: 1,
      page_size: 16,
      sort: "default",
      keyword: null,
    },
    imageHistory: [],
    lastFormData:{},
    popularRecomendations:[]
  }),
  getters: {
    // 👉 Getters
    getProducts: state => state.products,
    getSearchQuery: state => state.searchQuery,
    getCategories: state => state.categories,
    getIsLoading: state => state.isLoading,
    getIsLoadingMore: state => state.isLoadingMore,
    getTempImage: state => state.tempImage,
    getTempImageName: state => state.tempImageName,
    getProduct: state => state.product,
    getProductDetails: state => state.productDetails,
    getIsLoadingOptions: state => state.isLoadingOptions,
    getIsLoadingDetails: state => state.isLoadingDetails,
    getSearchHistory: state => state.searchHistory,
    getSuggestedProducts: state => state.suggestedProducts,
    getDetailPhotos: state => state.detailPhotos,
    getImageHistory: state => state.imageHistory,
    getProductsMlb: state => state.productsMlb,
    getCategorySelectedMlb: state => state.categorySelectedMlb,
    getPopularRecomendations: state => state.popularRecomendations,
    
  },
  actions: {

    addImageToHistory(image) {
      this.imageHistory.push(image)
    },
    setCategorySelectedMlb(category) {
      this.categorySelectedMlb = category
    },
    resetProductsMlb() {
      this.products = []
    },

    // 👉 Helper to manage loading states
    setLoading(isLoading, isMore = false) {
      if (isMore) {
        this.isLoadingMore = isLoading
      } else {
        this.isLoading = isLoading
      }
    },

    // 👉 Fetch products data
    async searchProducts() {
      const isMore = this.searchQuery.page > 1

      this.setLoading(true, isMore)
      try {
        this.productsMlb = []

        const response = await axios.post('products/search/keyword', this.searchQuery)
        if (this.searchQuery.page === 1) {
          this.products = response.data.data.items ?? []
        } else {
          this.products = [...this.products, ...response.data.data.items ?? []]
        }
        this.searchQuery.page += 1
        localStorage.setItem('allProducts', JSON.stringify(this.products))

        // Reordena a lista inteira após adicionar os novos itens
        if (this.searchQuery.sort == 'price_down') {
          this.products.sort((a, b) => b.float_price - a.float_price) // Maior para menor
        } else if (this.searchQuery.sort === 'price_up') {
          this.products.sort((a, b) => a.float_price - b.float_price) // Menor para maior
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.setLoading(false, isMore)
      }
    },

    // 👉 Fetch products data by Image
    async searchProductsByImage(formData, idxImageHistory = null) {
      localStorage.setItem('allProducts', JSON.stringify([]))

      const isMore = this.searchQuery.page > 1

      this.setLoading(true, isMore)
      if(formData){
        this.lastFormData = formData;
      }
      
      this.lastFormData.delete('page')
      this.lastFormData.delete('page_size')
      this.lastFormData.delete('sort')

      this.lastFormData.append('page', this.searchQuery.page)
      this.lastFormData.append('page_size', this.searchQuery.page_size)
      this.lastFormData.append('sort', this.searchQuery.sort)

      try {
        const response = await axios.post('products/search/image', this.lastFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (this.searchQuery.page === 1) {
          this.products = response.data.data.items
        } else {
          this.products = [...this.products, ...response.data.data.items]
        }
        localStorage.setItem('allProducts', JSON.stringify(this.products))
        if (idxImageHistory != null) {
          if (this.searchQuery.page === 1) {
            this.imageHistory = this.imageHistory.map((item, index) => {
              if (idxImageHistory === index) {
                return { ...item, response: response.data.data.items }
              }
              
              return item
            })
          } else {
            this.imageHistory = this.imageHistory.map((item, index) => {
              if (idxImageHistory === index) {
                return { 
                  ...item, 
                  response: [...(item.response || []), ...response.data.data.items], 
                }
              }
              
              return item
            })
          }
        }

        this.searchQuery.page += 1

        // Reordena a lista inteira após adicionar os novos itens
        if (this.searchQuery.sort == 'price_down') {
          this.products.sort((a, b) => b.float_price - a.float_price) // Maior para menor
        } else if (this.searchQuery.sort === 'price_up') {
          this.products.sort((a, b) => a.float_price - b.float_price) // Menor para maior
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.setLoading(false, isMore)
      }
    },

    async fetchProductById(productId) {
      try {
        const response = await axios.get(`products/${productId}`)

        this.isLoading = true
        this.product = response.data.data
        this.isLoadingOptions = false
        if (this.product.item_id) {
          setTimeout(() => {
            this.fetchProductDetails(productId)
            this.fetchDetailUrl(productId)

            // this.separateSuggestedProducts(null, response.data.data)
          }, 1000)

          this.isLoading = false
        }
      } catch (error) {
        this.isLoadingOptions = false
        console.error(error)
      }
    },
    async fetchPopularProductsCatalog(populateProducts = true) {
      try {
        const isMore = this.searchQuery.page > 1

        this.setLoading(true, isMore)
  
        
        const response = await axios.get(`products/popular?page=${this.searchQuery.page}&order_by=${this.searchQuery.sort}`)
        
        const productsMapped =  response.data.data.filter(el => el.is_similar).map(el =>{
      
          return {
            ...el.product_1688,
            img: el.product_mbl_thumbnail,
            title: el.product_mlb.title,
          }
        })
        if(populateProducts){
          if (this.searchQuery.page === 1) {
            this.products = productsMapped
          } else {
            this.products = [...this.products, ...productsMapped]
          }
        }
        this.popularRecomendations = productsMapped
        
        this.isLoadingOptions = false
        this.setLoading(false, isMore)

      } catch (error) {
        this.isLoadingOptions = false
        console.error(error)
      }
    },
    async fetchProductsMlb(categoryId) {
      try {
        const isMore = this.searchQuery.page > 1

        this.setLoading(true, isMore)
  
        
        const response = await axios.get(`products/category/${categoryId}?page=${this.searchQuery.page}&order_by=${this.searchQuery.sort}`)
        
        const productsMapped =     response.data.data.filter(el => el.is_similar).map(el =>{
      
          return {
            ...el.product_1688,
            img: el.product_mbl_thumbnail,
            title: el.product_mlb.title,
          }
        })

        if (this.searchQuery.page === 1) {
          this.products = productsMapped
        } else {
          this.products = [...this.products, ...productsMapped]
        }
        localStorage.setItem('allProducts', JSON.stringify(this.products))
        
        this.isLoadingOptions = false
        this.setLoading(false, isMore)

      } catch (error) {
        this.isLoadingOptions = false
        console.error(error)
      }
    },
    async fetchProductDetails(productId) {
      try {
        const response = await axios.get(`products/details/${productId}`)

        this.productDetails = response.data.data
        this.isLoadingDetails = false
      } catch (error) {
        console.error(error)
        this.isLoadingDetails = false
      }
    },

    // 👉 Forgot Keyword of Cache
    async forgotKeywordOfCache(item) {
      try {
        await axios.post('products/search/keyword/forgot', { keyword: item })
      } catch (error) {
        console.error(error)
      }
    },

    // 👉 Fetch categories
    async fetchCategories() {
      try {
        const response = await axios.get('products/categories')

        this.categories = response.data.data

      } catch (error) {
        console.error(error)
      }
    },
    async fetchDetailUrl(productId){
      try {

        const response = await axios.post(`products/photos/${productId}`, { 'url': this.product.detail_url })


        const result = JSON.parse(response.data.data)

        this.detailPhotos =  result.content
       
        this.isLoadingOptions = false

        
      } catch (error) {
        this.isLoadingOptions = false
        console.error(error)
      }
    },

    // 👉 Set search query
    setSearchQuery(searchQueryData) {
      this.searchQuery = {
        ...this.searchQuery,
        ...searchQueryData,
      }
    },

    setTempImage(file) {
      this.tempImage = file
    },
    setTempImageName(name) {
      this.tempImageName = name
    },
    setFormData(formData) {
      this.formData = formData
    },
    setProductsFromImageHistory(idx) {
      const item = this.imageHistory.find((_, index) => index === idx)
      if (item && item.response) {
        this.products = item.response 
      }
    },
    resetProduct() {
      this.product = {}
      this.isLoadingOptions = true
      this.isLoadingDetails = true
    },
    resetImageHistory() {
      this.imageHistory = []
    },
    resetProducts() {
      this.products = []
      this.searchQuery.page = 1
    },
    addToSearchHistory(item) {
      if (!this.searchHistory.includes(item)){
        this.searchHistory.push(item)
        localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
      }
    },
    updateSearchHistory(item) {
      const index = this.searchHistory.indexOf(item)
      if (index !== -1) {
        this.searchHistory.splice(index, 1)
      }
      this.searchHistory.push(item)
      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
    },
    removeFromSearchHistoy(item) {
      this.forgotKeywordOfCache(item)
      this.searchHistory = this.searchHistory.filter(i => i !== item)
      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
    },
    separateSuggestedProducts(allProducts, item) {
      let allProductsLocal =  JSON.parse(localStorage.getItem('allProducts'))
      
      if((!allProductsLocal || allProductsLocal.length == 0) && allProducts.length > 0) {
        localStorage.setItem('allProducts', JSON.stringify(allProducts))
      }
      
      if(Array.isArray(allProducts) && allProducts.length > 0) {  
        this.products = allProducts
      }else{
        this.products = allProductsLocal
      }
            
      let products = this.products.filter(i => {
        console.log(i)
        
        return i.item_id !== item.item_id
      })
      this.suggestedProducts = products

      if(products.length > 0){
        localStorage.setItem('suggestedProducts', JSON.stringify(this.suggestedProducts))
      }
    },
  },
})
