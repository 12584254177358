<script setup>
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { onMounted } from 'vue'
import { useTheme } from 'vuetify'
import WhatsappMobile from './@core/components/WhatsappMobile.vue'
import { useCartStore } from './views/apps/cart/useCartStore'

const cartStore = useCartStore()

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
  handleSkinChanges,
} = useThemeConfig()

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

onMounted(() => {
  cartStore.fetchCurrency()
})
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <!-- <ScrollToTop /> -->
      <WhatsappMobile v-if="$vuetify.display.mobile" />
    </VApp>
  </VLocaleProvider>
</template>

<style>
.v-icon:focus {
  box-shadow: none !important;
  outline: none !important;
}
.price-blur-1 {
  filter: blur(3px)
}
.price-blur-2 {
  filter: blur(6px)
}
.card-bottom-fixed {
  z-index: 9999;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background-color: white;
  padding: 16px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
}
.item-quantity .v-field__input {
  text-align: center;
}
span {
  text-transform: none !important;
}
</style>
