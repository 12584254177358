import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('SettingsStore', {
  state: () => ({
    isDrawerVisible: false,
    isCropperDialogVisible: false,
    accountItems: [
      { to: { name: 'home-account', query: { section: 1 } }, disabled: false, needLogin: true, text: 'Minhas solicitações', icon: 'mdi-swap-vertical' },
      { to: { name: 'home-account', query: { section: 0 } }, disabled: false, needLogin: true, text: 'Gerenciar conta', icon: 'mdi-cog-outline' },
      { to: { name: 'favorite-products' }, disabled: false, needLogin: true, text: 'Produtos Favoritos', icon: 'tabler-star-filled' },
    ],
    items: [
      { to: { name: 'search-products' }, disabled: false, needLogin: false, text: 'Todas as categorias', icon: 'mdi-apps' },
      { to: { name: 'champion-products' }, disabled: false, needLogin: true, text: 'Campeões de Venda', icon: 'mdi-brightness-percent' },
      { to: { name: '' }, disabled: true, needLogin: false, text: 'Market Insights PRO', icon: 'mdi-lightning-bolt' },
      { to: { name: '' }, disabled: true, needLogin: false, text: 'Conteúdos', icon: 'mdi-book-open-page-variant' },
      { to: { name: 'index' }, disabled: false, needLogin: false, text: 'Fale conosco', icon: 'mdi-help-circle-outline' },
    ],
  }),
  
  getters: {
    getIsDrawerVisible: state => state.isDrawerVisible,
    getDrawerAccountItems: state => {
      const authStore = useAuthStore()
      const isAdmin = authStore.getUserData?.role === 'admin'

      let filteredItems = state.accountItems

      if (isAdmin) {
        filteredItems = filteredItems.filter(item => !['Minhas solicitações', 'Gerenciar conta'].includes(item.text))
        filteredItems.unshift({ to: { path: '/dashboard/admin' }, disabled: false, needLogin: true, text: 'Painel Administrativo', icon: 'mdi-view-dashboard' })
      }

      return filteredItems
    },

    getDrawerItems: state => state.items,
    getIsCropperDialogVisible: state => state.isCropperDialogVisible,
  },
  actions: {
    setIsCropperDialogVisible(isCropperDialogVisible) {
      this.isCropperDialogVisible = isCropperDialogVisible
    },

    handleIsDrawerVisible(value) {
      this.isDrawerVisible = value
    },
  },
})

