import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import { VDateInput } from 'vuetify/labs/VDateInput'
import defaults from './defaults'
import { icons } from './icons'
import theme from './theme'

// Styles
import '@core-scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'

import { pt } from 'vuetify/locale'
 
export default createVuetify({
  aliases: {
    IconBtn: VBtn,
  },
  locale: {
    locale: 'pt',
    messages: { pt },
  },
  components: {
    VDateInput,
  },
  defaults,
  icons,
  theme,
})
