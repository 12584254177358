<script setup>
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const productsListStore = useProductsListStore()

const categories = ref(computed(() => productsListStore.getCategories))
const searchQuery = ref(computed(() => productsListStore.getSearchQuery))

const searchProducts = category => {

  searchQuery.value.page = 1
  searchQuery.value.keyword = null
  searchQuery.value.page_size = 16
  searchQuery.value.sort = "default"
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.resetProductsMlb()
  productsListStore.setCategorySelectedMlb(category)
  productsListStore.fetchProductsMlb(category.id)
  router.push({ name: 'search-products' })
}

const resolveImage = category => {
  let image = ''
  switch (category.name) {
  case "Acessórios para Veículos":
    image = '/images/acessorios-veiculos.webp'
    break
  case "Agro":
    image = '/images/agro.webp'
    break
  case "Alimentos e Bebidas":
    image = '/images/alimentos-bebidas.webp'
    break
  case "Animais":
    image = '/images/animais.webp'
    break
  case "Antiguidades e Coleções":
    image = '/images/antiguidades-colecoes.webp'
    break
  case "Arte, Papelaria e Armarinho":
    image = '/images/arte-papelaria-armarinho.webp'
    break
  case "Bebês":
    image = '/images/bebes.webp'
    break
  case "Beleza e Cuidado Pessoal":
    image = '/images/beleza-cuidado-pessoal.webp'
    break
  case "Brinquedos e Hobbies":
    image = '/images/brinquedos-hobbies.webp'
    break
  
  default:
    break
  }
  
  return image
}

onMounted(() => {
  if (categories.value.length === 0) {
    productsListStore.fetchCategories()
  }
})
</script>

<template>
  <section>
    <VRow v-if="!$vuetify.display.mobile">
      <VCol
        v-for="(category, index) in categories.slice(0, 9)"
        :key="index"
        cols="12"
        md="4"
        lg="4"
        @click="searchProducts(category)"
      >
        <VCard
          min-height="100px"
          class="pa-0 card"
        >
          <VCardText class="d-flex align-center pa-0">
            <VRow
              class="ma-0 pa-0"
              align="center"
            >
              <VCol
                cols="12"
                lg="6"
                md="6"
                class="pa-0"
              >
                <div class="pa-4">
                  <h4 class="card__title">
                    {{ category.name }}
                  </h4>
                </div>
              </VCol>
              <VCol
                cols="12"
                lg="6"
                md="6"
                class="pa-0"
              >
                <VImg
                  cover
                  :src="resolveImage(category)"
                  :alt="category"
                  :height="$vuetify.display.mobile ? '220px' :'120px'"
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
    <VList v-else>
      <VListItem
        v-for="(category, index) in categories.slice(0, 9)"
        :key="index"
        rounded="xl"
        @click="searchProducts(category)"
      >
        <template #prepend>
          <VImg
            :src="resolveImage(category)"
            height="40"
            width="40"
            class="rounded"
          />
        </template>
        <VListItemContent>
          <VListItemTitle class="text-h5 ps-3">
            {{ category.name }}
          </VListItemTitle>
        </VListItemContent>
        <template #append>
          <VIcon>mdi-chevron-right</VIcon>
        </template>
      </VListItem>
    </VList>
  </section>
</template>

<style scoped lang="scss">
  .card {
    border-radius: 16px;
    background-color: #222020;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }

    &__title {
      color: white;
      font-family: Poppins, sans-serif !important;
      font-size: 1.2rem;
      font-weight: 600 !important;
      word-wrap: normal;
    }
  }
</style>
