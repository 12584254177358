<script setup>
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { ref, watch } from 'vue'
import BoltText from '../BoltText.vue'
import SectionTitle from '../SectionTitle.vue'
import ProductCard from './ProductCard.vue'

const props = defineProps({
  isSuggestions: {
    type: Boolean,
    required: false,
  },
  quantity: {
    type: Number,
    required: false,
  },
})

const productsListStore = useProductsListStore()
const favoriteProducts = localStorage.getItem('favorite_products') ? JSON.parse(localStorage.getItem('favorite_products')) : []

const searchQuery = computed(() => productsListStore.getSearchQuery)
const items = computed(() => productsListStore.getProducts)
const categories = productsListStore.getCategories
const suggestedProducts = computed(() => productsListStore.getSuggestedProducts.filter(el => !favoriteProducts.includes(el.item_id) ))

const current_category = ref(0)
const selected_category = ref(categories[0] || {}) 

const searchProducts = category => {
  searchQuery.value.page = 1
  searchQuery.value.page_size = 5
  searchQuery.value.sort = "default"
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.resetProductsMlb()
  productsListStore.setCategorySelectedMlb(category)
  productsListStore.fetchProductsMlb(category.id)
}

// Calcula o menor preço
const cheapestProductPrice = computed(() => {
  if (!suggestedProducts.value.length) return null
  
  return Math.min(...suggestedProducts.value.map(product => product.price))
})

watch(
  () => categories,
  newCategories => {
    if (newCategories.length) {
      // selected_category.value = newCategories[0]
      // searchProducts(selected_category.value)
      // searchQuery.value.keyword = null

      productsListStore.resetProductsMlb()
      productsListStore.setCategorySelectedMlb(newCategories[0])
      productsListStore.fetchProductsMlb(newCategories[0].id)
    }
  },
  { immediate: true },
)

watch(
  () => current_category.value,
  newCategory => {
    productsListStore.resetProducts()
    selected_category.value = categories[newCategory]
    searchProducts(selected_category.value)
    searchQuery.value.keyword = null

    productsListStore.resetProductsMlb()
    productsListStore.setCategorySelectedMlb(categories[newCategory])
    productsListStore.fetchProductsMlb(categories[newCategory].id)
  },
)
</script>

<template>
  <section id="recommendations">
    <VCard
      v-if="categories && categories.length || props.isSuggestions"
      class="px-4"
      :class="{ 'my-10': !$vuetify.display.mobile }"
      style="border-radius: 26px;"
    >
      <div class="d-flex flex-wrap gap-2 align-center notranslate">
        <SectionTitle
          :class="{'mb-0' : props.isSuggestions, 'my-5': !$vuetify.display.mobile, 'mt-5': $vuetify.display.mobile}"
          :title="props.isSuggestions ? 'Alguns produtos similares que pode te interessar' : 'Recomendações'"
        />
        <div
          v-if="!props.isSuggestions"
          style=" padding: 0.2rem 0.6rem; border-radius: 8px;background-color: #222020;"
          :class="{ 'w-100 mb-5': $vuetify.display.mobile }"
        >
          <BoltText text="Importações em alta na sua região" />
        </div>
      </div>
      <div class="mt-1">
        <VBtnToggle
          v-if="!props.isSuggestions && !$vuetify.display.mobile"
          v-model="current_category"
          mandatory
          shaped
          rounded="xl"
          scrollable
          class="notranslate"
        >
          <VBtn
            v-for="(category, index) in categories.slice(0, 4)"
            :key="index"
            style="font-family: Poppins, sans-serif; font-weight: 400; letter-spacing: normal;"
            class="bg-grey-100 text-sm"
            @click="selected_category = category"
          >
            {{ category.name }}
          </VBtn>
        </VBtnToggle>
        <div v-if="props.isSuggestions">
          <VRow
            v-if="suggestedProducts.length > 0"
            class="d-flex py-7 flex-wrap"
          >
            <VCol
              v-for="(product, idx) in suggestedProducts.slice(0, props.quantity)"
              :key="idx"
              class="pa-2"
              cols="12"
              md="3"
            >
              <ProductCard
                :product="product"
                is-suggestions
                :is-offer="parseFloat(product.price) === Math.min(...suggestedProducts.slice(0, props.quantity).map(p => parseFloat(p.price)))"
              />
            </VCol>
          </VRow>
          <VRow v-else>
            <VCol
              v-for="n in props.quantity"
              :key="n"
              class="pa-2"
            >
              <VSkeletonLoader
                class="mx-auto"
                max-width="300"
                type="image, list-item-two-line"
              />
            </VCol>
          </VRow>
        </div>
        <div v-else>
          <VRow
            v-if="items.length > 0"
            class="d-flex py-7 flex-wrap"
          >
            <VCol
              v-for="(product, idx) in items.slice(0, 4)"
              :key="idx"
              class="pa-2"
              cols="12"
              md="3"
            >
              <ProductCard :product="product" />
            </VCol>
          </VRow>
          <VRow
            v-else
            class="my-4"
          >
            <VCol
              v-for="n in 5"
              :key="n"
              class="pa-2"
            >
              <VSkeletonLoader
                class="mx-auto"
                max-width="300"
                type="image, list-item-three-line"
              />
            </VCol>
          </VRow>
        </div>
      </div>
    </VCard>
  </section>
</template>

<style scoped lang="scss">
.card {
  border-radius: 16px;
  background-color: #222020;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }

  &__title {
    color: white;
    font-family: Poppins, sans-serif !important;
    font-size: 1.2rem;
    font-weight: 600 !important;
    word-wrap: normal;
  }
}
</style>
