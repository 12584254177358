<script setup>
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const productsListStore = useProductsListStore()

const searchHistory = computed(() => productsListStore.getSearchHistory.reverse())
const searchQuery = ref(computed(() => productsListStore.getSearchQuery))

const router = useRouter()

const searchProducts = item => {
  productsListStore.setCategorySelectedMlb({ id: '', name: '' })
  if (searchQuery.value.keyword !== item) {
    productsListStore.updateSearchHistory(item)
  }

  searchQuery.value.keyword = item
  searchQuery.value.page = 1
  searchQuery.value.page_size = 16
  searchQuery.value.sort = 'default'
  productsListStore.setTempImage(null)
  productsListStore.setTempImageName(null)
  productsListStore.setFormData(null)
  productsListStore.searchProducts()
  router.push('/search-products')
}
</script>

<template>
  <section
    v-if="searchHistory.length"
    class="notranslate"
  >
    <h2 class="text-h5 category-title mt-5">
      Histórico de busca
    </h2>
  
    <VList>
      <VListItem
        v-for="(item, index) in searchHistory"
        :key="index"
        :active="productsListStore.getCategorySelectedMlb.id == '' && searchQuery.keyword == item "

        rounded="xl"
        :title="item"
        :value="item"
        @click="searchProducts(item)"
      >
        <template #append>
          <VIcon
            icon="tabler-x"
            @click.stop="productsListStore.removeFromSearchHistoy(item)"
          />
        </template>
      </VListItem>
    </VList>
  </section>
</template>

<style lang="scss" scoped>
.category-title {
  font-family: Poppins;
}
</style>
