<script setup>
import { computed } from 'vue'

import brFlag from '@images/icons/countries/br.png'
import cnFlag from '@images/icons/countries/cn.png'


const openFacebook = item => {
  window.open(`https://www.facebook.com/profile.php?id=100095010491647`, '_blank')
}

const openInstagram = item => {
  window.open(`https://www.instagram.com/chinafacil.ai`, '_blank')
}

const companiesCn = [
  { 
    title: "CHINA - Shenzhen Office", 
    address: "3rd Floor, Phase 4, Halo Place, No. 260, Taoyuan Road, Sungang Street, Luohu District, Shenzhen, China", 
    country: "CN", 
  },
  { 
    title: "CHINA - GuangZhou Office", 
    address: "Room 1202, Fuli Tianhe Business Building, No. 4, Huating Road, Linhe East Road, Tianhe District, Guangzhou, China", 
    country: "CN", 
  },
  { 
    title: "CHINA - Yiwu Office", 
    address: "25F, Building 1, Zhongfu Plaza, Futian District, Yiwu, Zhejiang, China", 
    country: "CN", 
  },
]

const companiesBr = [
  { 
    title: "SEDE - Matriz e Armazém Geral", 
    address: "Rua Foz do Iguaçu, 351, CEP 08557-710, Poá - São Paulo", 
    country: "BR", 
  },
  { 
    title: "Filial Tatuapé", 
    address: "Rua Itapura, 395, CEP 03310-000, Tatuapé, São Paulo", 
    country: "BR", 
  },
  { 
    title: "Filial Aeroporto Internacional de São Paulo (GRU)", 
    address: "Rodovia Hélio Smidt, s/n, Edifício TECA - Sala 322, CEP 07190-100, Guarulhos - SP", 
    country: "BR", 
  },
  { 
    title: "Filial Aeroporto Internacional de Viracopos (VCP)", 
    address: "Rodovia Santos Dumont, km 66, 2º Andar - Sala 226, CEP 13052-901, Campinas - SP", 
    country: "BR", 
  },
  { 
    title: "Filial Santos", 
    address: "Rua Augusto Severo, Nº588 - 9º Andar, CEP 11010-050, Santos - São Paulo", 
    country: "BR", 
  },
  { 
    title: "Filial Santa Catarina", 
    address: "Av. 7 de Setembro, 776 - Sala 501 - S07, CEP 88301-202, Itajaí - SC", 
    country: "BR", 
  },
  { 
    title: "Filial Rio de Janeiro", 
    address: "Rua Visconde de Inhauma, 134 - Sala 2001 - Centro, CEP 20091-901, Rio de Janeiro - RJ", 
    country: "BR", 
  },
]


const companyLogoMap = {
  'BR': brFlag,
  'CN': cnFlag,
}

const groupedCompanies = computed(() => {
  const groups = []
  for (let i = 0; i < companies.length; i += 3) {
    groups.push(companies.slice(i, i + 3))
  }
  
  return groups
})
</script>

<template>
  <VSheet
    id="footer"
    class="notranslate"
  >
    <VContainer>
      <VRow
        class="py-16"
        :class="{'px-14': !$vuetify.display.mobile, 'px-0': $vuetify.display.mobile}"
      >
        <VCol
          cols="12"
          md="3"
          class="d-flex align-center flex-column"
        >
          <img
            width="200"
            src="/images/logo-vertical.svg"
          >
          <section class="d-flex gap-4 mt-4">
            <VIcon
              size="30"
              color="#9E9E9E"
              @click="openFacebook"
            >
              mdi-facebook
            </VIcon>
            <VIcon
              size="30"
              color="#9E9E9E"
              @click="openInstagram"
            >
              mdi-instagram
            </VIcon>
          </section>
        </VCol>
        <VCol
          cols="12"
          md="3"
        />
        <VCol
          cols="12"
          md="3"
        >
          <p
            class="text-h5 mb-1"
            :style="{color: '#9E9E9E'}"
          >
            China Fácil
          </p>
          <ul class="pl-5">
            <li><a href="/about">Sobre Nós</a></li>
            <li><a href="/policies">Política de Privacidade</a> </li>
            <li>Termos de Uso</li>
          </ul>
        </VCol>
        <VCol
          cols="12"
          md="3"
        >
          <p
            class="text-h5 mb-1"
            :style="{color: '#9E9E9E'}"
          >
            Serviços
          </p>
          <ul class="pl-5">
            <li>Inteligência de Mercado</li>
            <li>Importação da China</li>
            <li>Pesquisa de Fornecedores</li>
            <li>Simulação de Custos</li>
          </ul>
        </VCol>
      </VRow>


      <VRow
        align="stretch"
        class="mb-5"
      >
        <VCol
          class="h-100"
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="8"
          xl="8"
          xxl="8"
        >
          <VCard>
            <VCardTitle
              class="py-5 d-flex align-center"
              style=" background: linear-gradient(to right, #eaf0dd, #f3f3f3);"
            >
              <VAvatar
                size="25"
                class="mx-2"
                color="secondary"
                :image="companyLogoMap['BR']"
              />
              <div class="d-flex align-center justify-center  ">
                <p class="text-h5 mr-2 mb-0">
                  Unidades no Brasil 
                </p>
                <p class="text-body-1 mb-0">
                  {{ companiesBr.length }} endereços
                </p>
              </div>
            </VCardTitle>
            <VCardText class="py-5">
              <VRow class="mb-3 pt-3">
                <VCol
                  v-for="(company) in companiesBr"
                  :key="company.titulo"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  class="d-flex align-center border-primary	pt-0"
                >
                  <div class="d-flex flex-column">
                    <p class="text-h6 text-base mb-0 font-weight-black font-weight-light		">
                      {{ company.title }}
                    </p>
          
      
                    <span class="text-subtitle-2 	">{{ company.address }}</span>
                  </div>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VCol>
        <VCol
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="4"
          xl="4"
          xxl="4"
        >
          <VCard class="h-100">
            <VCardTitle
              class="py-5 d-flex align-center"
              style=" background: linear-gradient(to right, #f7eaea, #f3f3f3);"
            >
              <VAvatar
                size="25"
                class="mr-4"
                color="secondary"
                :image="companyLogoMap['CN']"
              />
              <div class="d-flex align-center justify-center  ">
                <p class="text-h5 mr-2 mb-0">
                  Unidades na China 
                </p>
                <p class="text-body-1 mb-0">
                  {{ companiesCn.length }} endereços
                </p>
              </div>
            </VCardTitle>
            <VCardText class="py-5">
              <VRow class="mb-3 pt-3">
                <VCol
                  v-for="(company) in companiesCn"
                  :key="company.titulo"
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  class="d-flex align-center border-primary	pt-0"
                >
                  <div class="d-flex flex-column">
                    <p class="text-h6 text-base mb-0 font-weight-black font-weight-light		">
                      {{ company.title }}
                    </p>
          
      
                    <span class="text-subtitle-2 	">{{ company.address }}</span>
                  </div>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
      <!--

      -->
    </VContainer>
  </VSheet>
</template>
