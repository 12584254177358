<script setup>
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { useCartStore } from '@/views/apps/cart/useCartStore'
import { useProductsListStore } from '@/views/apps/products/useProductsListStore'
import { useRouter } from 'vue-router'

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  isOffer: {
    type: Boolean,
    default: false,
  },
  isSuggestions: {
    type: Boolean,
    default: false,
  },
})

const authStore = useAuthStore()

const accessToken = computed(() => authStore.getAccessToken)

const isMlbProduct = computed(() => {
  return productsListStore.getCategorySelectedMlb.id != ''
})

const router = useRouter()

const cartStore = useCartStore()
const productsListStore = useProductsListStore()

const bid = computed(() => cartStore.getBid)

const shortTitle = title => {
  return title.length >12 ? title.substring(0, 12) + "..." : title
}

const viewDetails = () => {
  const allProducts = productsListStore.getProducts ?? JSON.parse(localStorage.getItem('suggestedProducts'))
  const productRateInfo = JSON.parse(localStorage.getItem('product_rate')) ?? []
  const productQuantityPrices = JSON.parse(localStorage.getItem('product_quantity_prices')) ?? []

  if(productRateInfo.filter(el => el.id == props.product.item_id).length == 0) {
    productRateInfo.push({
      id: props.product.item_id,
      rate: props.product.goods_score,
    })
  }
  if(productQuantityPrices.filter(el => el.id == props.product.item_id).length == 0) {
    productQuantityPrices.push({
      id: props.product.item_id,
      prices: props.product.quantity_prices,
    })
  }

  localStorage.setItem(`product_rate`, JSON.stringify(productRateInfo))
  localStorage.setItem(`product_quantity_prices`, JSON.stringify(productQuantityPrices))
  productsListStore.separateSuggestedProducts(allProducts, props.product)
  if (props.isSuggestions) {
    router.push(`/details/${props.product.item_id}`)
  } else {
    window.open(`/details/${props.product.item_id}`, '_blank')
  }  
}

const formatPrice = price => {

  price = price * bid.value

  if (isNaN(price)) {
    return 'Consultar'
  }

  return Number(price).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

}

const calculatePriceInBrazil = price => {
  price = price * bid.value
  
  if (isNaN(price)) {
    return 'Consultar'
  }

  let priceBrazil = price * 2

  return Number(priceBrazil).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}


const phoneConfirmed = computed(() => authStore.phoneConfirmed)

const viewRegisterDialog = async () => {
  if (accessToken.value && phoneConfirmed.value ) return 
  if(!phoneConfirmed.value && accessToken.value){
    authStore.setWindowRegister(1)
  }
  authStore.setIsRegisterDialogVisible(true)
}

const blurActive = computed(() =>{
  return !accessToken.value || !phoneConfirmed.value
})
</script>

<template>
  <VCard
    :flat="!$vuetify.display.mobile"
    @click="viewDetails"
  >
    <VImg
      class="card__image"
      :class="{'mobile-img': $vuetify.display.mobile}"
      cover
      :height="$vuetify.display.mobile ? '250px' :'200px'"
      :src="product.img"
    >
      <VChip
        v-if="props.isOffer"
        class="ma-2"
        color="primary"
        variant="flat"
      >
        Oferta
      </VChip>
    </VImg>
    <VCardText :class="{'pa-4': $vuetify.display.mobile, 'pa-1': !$vuetify.display.mobile }">
      <VRating
        v-model="product.goods_score"
        readonly
        :size="$vuetify.display.mobile ? '' : 'x-small'"
        density="compact"
      />
      <div style=" position: relative; overflow: hidden; max-height: 2rem;">
        <p
          class="card__text elippsis-paragraph"
          :class="{'text-subtitle-1 ps-1': $vuetify.display.mobile,'notranslate':isMlbProduct}"
        >
          {{ product.title ? product.title : '' }}
        </p>
      </div>
    </VCardText>
    <VCardActions
      class="notranslate mt-2"
      :class="{'px-5': $vuetify.display.mobile, 'px-1': !$vuetify.display.mobile }"
    >
      <section class="d-flex justify-space-between w-100">
        <section>
          <p class="mb-0 font-weight-bold text-caption">
            🇨🇳 Preço na China
          </p>
          <p
            class="font-weight-bold mb-0"
            :class="{'text-h5': $vuetify.display.mobile}"
          >
            {{ formatPrice(product.price) }}
          </p>
        </section>
        <section>
          <section :class="{'cursor-pointer':blurActive}">
            <p class="mb-0 font-weight-bold text-caption">
              🇧🇷 Preço no Brasil
            </p>
            <section class="d-flex align-center gap-2">
              <p
                class="mb-0 price-blur text-body-2"
                :class="{'price-blur-1' : blurActive}"
              >
                {{ accessToken && phoneConfirmed ? calculatePriceInBrazil(product.price) : "R$ 000,00" }}
              </p>
              <section v-if="!accessToken || !phoneConfirmed">
                <VIcon
                  icon="tabler-eye"
                  @click.stop="viewRegisterDialog"
                />
                <VTooltip
                  activator="parent"
                  location="start"
                  class="notranslate"
                >
                  Clique para ver o preço no Brasil
                </VTooltip>
              </section>
            </section>
          </section>
          <VTooltip
            v-if="accessToken && phoneConfirmed"
            activator="parent"
            location="start"
            class="notranslate"
          >
            Preço estimado nacionalizado no Brasil<br>considerando impostos, frete e despesas
          </VTooltip>
        </section>
      </section>
    </VCardActions>
  </VCard>
</template>

<style lang="scss" scoped>
.v-card {
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

.card {
  &__image {
    border-radius: 10px;
  }

  &__text {
    color: #1a1a1a;
    font-size: 14px;
    line-height: normal;
  }

  &__button {
    border: 1px solid #222020;
    inline-size: 100%;
  }
}

.mobile-img {
  border-radius: 10px 10px 0 0;
}

.elippsis-paragraph {
  word-break: break-all !important;

  &::after {
    position: absolute;

    // background: linear-gradient(to left, white 10%, transparent 20%);
    background: white;
    block-size: 1rem;
    content: "...";
    inline-size: fit-content;
    inset-block-end: 0;
    inset-inline-end: 0;
    text-align: end;
  }
}
</style>
