<script setup>
import AuthDialog from '@/components/dialogs/AuthDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import CropperDialog from '@/components/dialogs/CropperDialog.vue'
import RegisterDialog from '@/components/dialogs/RegisterDialog.vue'
import { initialAbility } from '@/plugins/casl/ability'
import { useAppAbility } from '@/plugins/casl/useAppAbility'
import { useAuthStore } from '@/views/apps/auth/useAuthStore'
import { useCartStore } from '@/views/apps/cart/useCartStore'
import { useSettingsStore } from '@/views/apps/settings/useSettingsStore'
import { GoogleTranslateSelect } from '@google-translate-select/vue3'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import ProductsCart from './cart/ProductsCart.vue'
import SearchProducts from './search-products/SearchProducts.vue'

const cartStore = useCartStore()
const cartCount = computed(() => cartStore.getCartCount)
const cartItems = computed(() => cartStore.getCart)
const router = useRouter()
const ability = useAppAbility()
const localUser = ref(JSON.parse(localStorage.getItem('userData')))
const accessToken = ref(localStorage.getItem('accessToken'))
const confirmDialog = ref(null)
const isConfirmDialogVisible = ref(false)

const handleLogout = () => {
  isConfirmDialogVisible.value = true
}

const settingsStore = useSettingsStore()

const authStore = useAuthStore()
const userData = computed(() => authStore.getUserData)
const isCropperDialogVisible = computed(() => settingsStore.getIsCropperDialogVisible)
const isAuthDialogVisible = computed(() => authStore.getIsAuthDialogVisible)
const isRegisterDialogVisible = computed(() => authStore.getIsRegisterDialogVisible)

const logout = () => {

  localUser.value = null
  accessToken.value = null

  // Remove "userData" from localStorage
  localStorage.removeItem('userData')

  // Remove "accessToken" from localStorage
  localStorage.removeItem('accessToken')

  // Remove "userAbilities" from localStorage
  localStorage.removeItem('userAbilities')

  // Reset ability to initial ability
  ability.update(initialAbility)
}

const setLoggedUser = response => {
  const { token, data } = response

  localUser.value = data
  accessToken.value = token
}

const viewLoginDialog = () => {
  setTimeout(() => {
    authStore.setIsAuthDialogVisible(true)
  }, 500)
}

const viewRegisterDialog = () => {
  setTimeout(() => {
    authStore.setIsRegisterDialogVisible(true)
  }, 500)
}

const route = useRoute()

const mainNavBar = computed(() => { 
  
  return ['index', 'search-products', 'details-id'].includes(route.name ?? '')
})

const openWhatsapp = () => {
  window.open('https://wa.me/5511910002023', '_blank')
}

const handleGoogleTranslateSelect = language => {
  //
}

const items =  computed(() => {
  
  return [
    { to: { 'name': 'search-products' }, disabled: false, needLogin: false, text: 'Todas as categorias', icon: 'mdi-apps' },
    { to: { 'name': 'champion-products' }, disabled: false, needLogin: false, text: 'Campeões de Venda', icon: 'mdi-brightness-percent' },
    { to: { 'name': 'favorite-products' }, disabled: false, needLogin: true, text: 'Produtos Favoritos', icon: 'tabler-star-filled' },
    { to: { 'name': '' }, disabled: true,  needLogin: false, text: 'Market Insights PRO', icon: 'mdi-lightning-bolt' },
    { to: { 'name': '' }, disabled: true,  needLogin: false, text: 'Conteúdos', icon: 'mdi-book-open-page-variant' },
    { to: { 'name': 'index' }, disabled: false, needLogin: false, text: 'Fale conosco', icon: 'mdi-help-circle-outline' },
  ]
})

const destinationRoute = computed(() => {
  if (localUser.value.role === 'admin') {
    return { 'name': 'dashboard-admin' }
  } else {
    return { 'name': 'home-account' }
  }
})

const accountItems = computed(() => {
  return [
    { to: { 'name': 'home-account', query: { section: 1 } }, disabled: false, needLogin: true, text: 'Minhas solicitações', icon: 'mdi-swap-vertical' },
    { to: { 'name': 'home-account', query: { section: 0 }  }, disabled: false, needLogin: true, text: 'Gerenciar conta', icon: 'mdi-cog-outline' },
  ]
})

const navigate = () => {
  if (localUser.value.role === 'admin') {
    window.location.href = "/dashboard/admin"
    
    return
  }
  router.push(destinationRoute.value)
}

const continueToCard = () => {
  router.push({ name: 'cart' })
}

const phoneConfirmed = computed(() => {    
  return authStore.phoneConfirmed
})

const goTo = async item => {
  if (item.name === 'champion-products' && !accessToken.value) {
    authStore.setNextRouteName(item.name)
    authStore.setIsRegisterDialogVisible(true)
    
    return
  }
  if (item.name === 'champion-products' && !phoneConfirmed.value) {
    authStore.setWindowRegister(1)
    authStore.setIsRegisterDialogVisible(true)
    
    return
  }
  if (item.name === 'champion-products') {
    window.location.href = '/champion-products'
    
    return
  }
  router.push(item)
}

const onClickStop = el => {
  el.stopPropagation()
}
</script>

<template>
  <div
    class="d-flex justify-content-between justify-center gap-2 notranslate"
    :class="{ 'mt-4': !$vuetify.display.mobile }"
    style="position: fixed; z-index: 10; width: 100%;"
  >
    <div
      class="landing-page-navbar"
      :class="{'mobile': $vuetify.display.mobile}"
    >
      <VRow>
        <VCol :class="{ 'px-5': !$vuetify.display.mobile }">
          <div
            class="d-flex h-100 align-center gap-5  py-1"
            :class="{'justify-space-between': $vuetify.display.mobile, }"
          >
            <div class="d-flex  justify-space-around">
              <div
                v-if="!$vuetify.display.mobile"
                class="me-3"
              >
                <VMenu transition="slide-y-transition">
                  <template #activator="{ props }">
                    <VIcon
                      v-bind="props"
                      icon="mdi-menu"
                      :size="$vuetify.display.mobile? '25' : '30'"
                      color="black"
                    />
                  </template>
                  <VList>
                    <VListItem
                      v-for="(item, i) in items"
                      :key="i"
                      :disabled="item.disabled || (item.needLogin && !accessToken)"
                      slim
                      exact
                      color="transparent"
                      @click="goTo(item.to)"
                    >
                      <template #prepend>
                        <VIcon
                          :icon="item.icon"
                          color="black"
                        />
                      </template>
                      <div class="d-flex align-center">
                        <VListItemTitle class="notranslate">
                          {{ item.text }}
                        </VListItemTitle>
                        <VChip 
                          v-if="item.disabled && !item.needLogin"
                          class="mx-2"
                          color="primary"
                          variant="outlined"
                        >
                          <VIcon
                            icon="mdi-clock-outline"
                            start
                            class="notranslate"
                            size="16"
                          />
                          Em Breve
                        </VChip>
                      </div>
                    </VListItem>
                  </VList>
                </VMenu>
              </div>
              <VIcon
                v-if="$vuetify.display.mobile"
                icon="mdi-menu"
                class="me-3"
                :size="$vuetify.display.mobile? '25' : '30'"
                color="black"
                @click.stop="settingsStore.handleIsDrawerVisible(true)"
              />
              
              <img
                src="/images/Logo.svg"
                alt="China Fácil"
                class=" d-lg-block cursor-pointer"
                width="170"
                @click="router.push({ path: '/' })"
              >
            </div>
           
            <SearchProducts v-if="!$vuetify.display.mobile" />

      
            <div class="d-flex  justify-space-around align-center ga-3">
              <VBadge
                v-if="$vuetify.display.mobile"
                color="primary"
                :content="cartCount"
                size="small"
                small
                class="notification-badge me-2"
              >
                <VIcon
                  size="24"
                  icon="tabler-shopping-cart"
                  @click="router.push({ path: '/cart' })"
                />
              </VBadge>
              <VMenu
                v-else
                v-model="cartStore.isCartMenuOpen"
                transition="slide-y-transition"
                class="notranslate"
              >
                <template #activator="{ props }">
                  <VBadge
                    color="primary"
                    :content="cartCount"
                    size="small"
                    small
                    class="notification-badge me-2"
                  >
                    <VIcon
                      size="24"
                      v-bind="props"
                      icon="tabler-shopping-cart"
                    />
                  </VBadge>
                </template>
                <VSheet
                  class="pa-4 ma-4"
                  :style="{width: '500px'}"
                  @click="onClickStop"
                >
                  <section id="cart">
                    <section class="mb-4 d-flex align-center justify-space-between">
                      <h1
                        v-if="!$vuetify.display.mobile"
                        class="title notranslate"
                      >
                        Carrinho
                      </h1>
                      <section
                        v-if="cartItems.length > 0"
                        class="d-flex align-center gap-1 cursor-pointer"
                        @click="continueToCard"
                      >
                        <p class="mb-0 font-weight-bold text-primary">
                          Ir para o checkout 
                        </p>
                        <VIcon>tabler-arrow-right</VIcon>
                      </section>
                    </section>
                    <section
                      v-if="cartCount === 0"
                      class="notranslate"
                      :class="{ 'd-flex flex-column align-center text-h6': $vuetify.display.mobile }"
                    >
                      <VIcon
                        v-if="$vuetify.display.mobile"
                        icon="tabler-shopping-cart-exclamation"
                        size="100"
                        class="mb-4"
                      />
                      <p
                        class="subtitle"
                        :class="{ 'text-h5 mb-0': $vuetify.display.mobile }"
                      >
                        Seu carrinho está vazio.
                      </p>
                      <p
                        v-if="$vuetify.display.mobile"
                        class="subtitle"
                        :class="{ 'text-h6 mb-0': $vuetify.display.mobile }"
                      >
                        Busque por produtos e adicione ao carrinho.
                      </p>
                      <VBtn
                        v-if="$vuetify.display.mobile"
                        color="primary"
                        rounded
                        class="mt-5"
                        @click.stop="router.push({ name: 'search-products' })"
                      >
                        Buscar produtos
                      </VBtn>
                    </section>
                    <ProductsCart
                      class="translate"
                      :items="cartItems"
                      is-menu
                    />
                  </section>
                </VSheet>
              </VMenu>
              <VBtn
                v-if="accessToken && !$vuetify.display.mobile && localUser.role == 'admin'"
                variant="tonal"
                rounded
    
                @click="navigate"
              >
                Acessar Conta
              </VBtn>

              <VMenu
                v-if="accessToken && localUser.role != 'admin'"
                transition="slide-y-transition"
                class="notranslate"
              >
                <template #activator="{ props }">
                  <VBtn
                    v-if="accessToken && !$vuetify.display.mobile && localUser.role != 'admin'"
                    variant="tonal"
                    rounded
                    v-bind="props"
                  >
                    Acessar Conta
                  </VBtn>
                </template>
      
                <VList>
                  <VListItem>
                    <div class="d-flex justify-start h-1">
                      <VBadge
                        dot
                        bordered
                        location="bottom right"
                        offset-x="2"
                        offset-y="2"
                        color="success"
                        class="me-3"
                      >
                        <VAvatar
                          class="cursor-pointer"
                          :color="!(userData && userData.avatar) ? 'primary' : undefined"
                          :variant="!(userData && userData.avatar) ? 'tonal' : undefined"
                          :class="{'border border-2 border-light': userData.avatar}"
                          size="45"
                        >
                          <VImg
                            v-if="userData.avatar"
                            :src="`/avatars/${userData.avatar}`"
                          />
                          <VIcon
                            v-else
                            icon="tabler-user"
                          />
                        </VAvatar>
                      </VBadge>
                  
                      <div style="display: block;">
                        <p class="my-0 font-weight-black text-h5">
                          {{ userData.name }}
                        </p>
                        <p class="my-0 text-body-1 font-weight-light">
                          {{ userData.email }}
                        </p>
                      </div>
                    </div>
                  </VListItem>
                  
                  <VListItem
                    v-for="(item, i) in accountItems"
                    :key="i"
                    :disabled="item.disabled || (item.needLogin && !accessToken)"
                    slim
                    exact
                    color="transparent"
                    @click="router.push(item.to)"
                  >
                    <template #prepend>
                      <VIcon
                        :icon="item.icon"
                        color="black"
                      />
                    </template>
                    <div class="d-flex align-center">
                      <VListItemTitle class="notranslate">
                        {{ item.text }}
                      </VListItemTitle>
                      <VChip 
                        v-if="item.disabled && !item.needLogin"
                        class="mx-2"
                        color="primary"
                        variant="outlined"
                      >
                        <VIcon
                          icon="mdi-clock-outline"
                          start
                          class="notranslate"
                          size="16"
                        />
                        Em Breve
                      </VChip>
                    </div>
                  </VListItem>
                  <VDivider />
                  <VListItem class="d-flex justify-center">
                    <VBtn
                      prepend-icon="tabler-logout"
                      color="transparent"
                      variant="tonal"
                      rounded
                      @click="handleLogout"
                    >
                      Desconectar
                    </VBtn>
                  </VListItem>
                </VList>
              </VMenu>
              <VBtn
                v-if="!accessToken && ! $vuetify.display.mobile "
                color="primary"
                prepend-icon="tabler-user"
                class=""
                rounded
                @click="authStore.setIsAuthDialogVisible(true)"
              >
                Entrar
              </VBtn>
            </div>
            <GoogleTranslateSelect
              v-if="route.name != 'champion-products'"
              default-language-code="pt"
              default-page-language-code="zh-CN"
              :fetch-browser-language="false"
              trigger="click"
              class="d-none"
              @select="handleGoogleTranslateSelect"
            />
            <ConfirmDialog
              :is-dialog-visible="isConfirmDialogVisible"
              :confirm-dialog="confirmDialog"
              confirmation-question="Deseja realmente finalizar a sessão?"
              confirm-button-title="Sair"
              hide-confirm
              hide-cancel
              @update:is-dialog-visible="isConfirmDialogVisible = $event"
              @confirm="logout"
            />
            <AuthDialog
              :is-dialog-visible="isAuthDialogVisible"
              @update:is-dialog-visible="authStore.setIsAuthDialogVisible($event)"
              @create-account="viewRegisterDialog"
              @set-logged-user="setLoggedUser"
            />
            <RegisterDialog
              :is-dialog-visible="isRegisterDialogVisible"
              @update:is-dialog-visible="authStore.setIsRegisterDialogVisible($event)"
              @login="viewLoginDialog"
              @set-logged-user="setLoggedUser"
            />
            <CropperDialog
              :is-dialog-visible="isCropperDialogVisible"
              @update:is-dialog-visible="settingsStore.setIsCropperDialogVisible($event)"
            />
          </div>
        </VCol>
      </VRow>
      <VRow
        v-if="$vuetify.display.mobile"
        class="my-0"
      >
        <VCol>
          <SearchProducts v-if="mainNavBar" />
          <div class="d-flex align-center">
            <VIcon
              v-if="$vuetify.display.mobile && !mainNavBar"
              size="24"
              class="mr-4"
              icon="mdi-arrow-left"
              @click="router.go(-1)"
            />
            <p
              v-if="!['Home', 'Search', 'Detalhes do produto'].some(term => (route.meta.title || '').includes(term)) && $vuetify.display.mobile"
              class="text-h5 font-weight-bold mb-0"
            >
              {{ route.meta.title }}
            </p>
          </div>
        </VCol>
      </VRow>
    </div>
    <VImg
      v-if="!$vuetify.display.mobile"
      src="/images/wpp_button.svg"
      alt="Entrar em contato com a China Fácil"
      max-width="60"
      class="cursor-pointer"
      @click="openWhatsapp"
    />
  </div>
</template>

<style lang="scss">
.landing-page-navbar {
  padding: 10px;
  border-radius: 100px;
  background: white;
  inline-size: 80%;
}

.mobile {
  border-radius: 0;
  inline-size: 100%;
  padding-block: 16px 0;
  padding-inline: 16px;
}

.import-search-field .v-field__append-inner .v-icon {
  color: #222020 !important;
  opacity: 1 !important;
}

#google_translate_element {
  display: none;
}
</style>
