import axios from '@axios'
import { defineStore } from 'pinia'

export const useAuthStore = defineStore('AuthStore', {
  state: () => ({
    isAuthDialogVisible: false,
    isRegisterDialogVisible: false,
    nextRouteName: null,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    accessToken: localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : '',
    userAbilities: localStorage.getItem('userAbilities') ? JSON.parse(localStorage.getItem('userAbilities')) : [],
    isDialogVisible: false,
    dialogData: { title: '' },
    windowRegister: 0,
    phoneConfirmed: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')).phone_verified : false, 
    resetPasswordSucessfull: false,
    loading: false,
  }),
  getters: {
    // 👉 Getters
    getIsAuthDialogVisible: state => state.isAuthDialogVisible,
    getIsRegisterDialogVisible: state => state.isRegisterDialogVisible,
    getUserData: state => state.userData,
    getAccessToken: state => state.accessToken,
    getUserAbilities: state => state.userAbilities,
    getNextRouteName: state => state.nextRouteName,
    getIsDialogVisible: state => state.isDialogVisible,
    getDialogData: state => state.dialogData,
    getWindowRegister: state => state.windowRegister,
    getPhoneConfirmed: state => state.phoneConfirmed,
    getResetSuccessfull: state => state.resetPasswordSucessfull,
    getLoading: state => state.loading
  },
  actions: {
    // 👉 handle Auth Dialog
    setIsAuthDialogVisible(isAuthDialogVisible) {
      this.isAuthDialogVisible = isAuthDialogVisible
    },

    // 👉 handle Register Dialog
    setIsRegisterDialogVisible(isRegisterDialogVisible) {
      this.isRegisterDialogVisible = isRegisterDialogVisible
    },
    setWindowRegister(number) {
      this.windowRegister = number
    },
    setNextRouteName(nextRouteName)  {
      this.nextRouteName = nextRouteName
    },
    setUserData(userData) {
      if (userData.data) {
        this.userData = userData.data
        localStorage.setItem('userData', JSON.stringify(userData.data))
        localStorage.setItem('favorite_products', JSON.stringify(userData.data.favorites))
      }
      
      if (userData.token) {
        this.accessToken = userData.token
        localStorage.setItem('accessToken', JSON.stringify(userData.token))
      }
        
      if (userData.abilities) {
        this.userAbilities = userData.abilities
        localStorage.setItem('userAbilities', JSON.stringify(userData.abilities))
      }
    },
    logout() {
      this.userData = {}
      this.accessToken = ''
      this.userAbilities = {}
    
      // Remove "userData" from localStorage
      localStorage.removeItem('userData')
    
      // Remove "accessToken" from localStorage
      localStorage.removeItem('accessToken')
    
      // Remove "userAbilities" from localStorage
      localStorage.removeItem('userAbilities')
    },
    updateUserPhone(id, phone) {
      this.isLoading = true
      axios.patch(`/user-update-phone/${id}`, {
        phone,
      })
        .then(response => {
          localStorage.removeItem('userData')
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Sucesso!',
            message: response.data.message,
            type: 'success',
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isDialogVisible = true
          this.dialogData = {
            title: 'Ops!',
            message: 'Erro ao atualizar usuário, tente novamente!',
            type: 'error',
          }
          console.error(error)
          this.isLoading = false
        })
    },

    async sendCodeVerification(phoneNumber){

      const externalAxiosInstance = axios.create({
        baseURL: import.meta.env.VITE_VELOX_OTP_API_URL,

      })

      
      return await externalAxiosInstance.post(`/send`, {
        'channel': 'sms',
        'target': phoneNumber,
      }, {
        headers: {
          'Authorization': `Bearer ${import.meta.env.VITE_VELOX_OTP_API_KEY}`,
        },
      }).then(response => {
        
        return response.data

      })
        .catch(error => {
          this.isDialogVisible = true
          console.log(error)
          this.dialogData = {
            title: 'Ops!',
            message: error.response.data.message,
            type: 'error',
          }
          
          return error.response.data

        })
    },    
    async reSendCodeVerification(phoneNumber){

      const externalAxiosInstance = axios.create({
        baseURL: import.meta.env.VITE_VELOX_OTP_API_URL,
      })
      
      return await externalAxiosInstance.post(`/resend`, {
        'channel': 'sms',
        'target': phoneNumber,
      }, {
        headers: {
          'Authorization': `Bearer ${import.meta.env.VITE_VELOX_OTP_API_KEY}`,
        },
      }).then(response => {

        return response.data

      })
        .catch(error => {
          return error.response.data

        })
    },

    async validateOtpCode(code, phoneNumber){
      const result = []

      const externalAxiosInstance = axios.create({
        baseURL: import.meta.env.VITE_VELOX_OTP_API_URL,

      })

      
      return await externalAxiosInstance.post(`${import.meta.env.VITE_VELOX_OTP_API_URL}/validate`, {
        'otp_code': code,
        'target': phoneNumber,
      }, {
        headers: {
          'Authorization': `Bearer ${import.meta.env.VITE_VELOX_OTP_API_KEY}`,
        },
      }).then(async response => {
        await this.verifyPhone(this.userData)

        return response.data
      })
        .catch(error => {
          return error.response.data
        })
    },
    async verifyPhone(user){

      return await axios.patch(`/validate-phone/${user.id}`).then(response => {

        localStorage.removeItem('userData')
        localStorage.setItem('userData', JSON.stringify(response.data.data))
        this.phoneConfirmed = response.data.data.phone_verified
        this.isDialogVisible = true
        this.dialogData = {
          title: 'Sucesso!',
          message: response.data.message,
          type: 'success',
        }
        
      }).catch(error => {
       
      })
    },

    async sendResetEmail(email) {
      this.loading = true;
      const url = import.meta.env.VITE_API_URL.toString();
      const externalAxiosInstance = axios.create({
        baseURL: url.replace('api',''),

      })
      return await externalAxiosInstance.post(`forgot-password`, { email }).then(response => {


        this.isDialogVisible = true
        this.dialogData = {
          title: 'Sucesso!',
          message: 'Email de redefinição de senha enviado',
          type: 'success',
        }
        this.loading = false;

      }).catch(error => {
        console.log(error)
        this.isDialogVisible = true
        this.dialogData = {
          title: 'Erro!',
          message: 'Erro ao enviar email! Verifique sua caixa de email',
          type: 'error',
        }
        this.loading = false;

      })
    },
    async resetPassword(email,token,password,password_confirmation) {
      const url = import.meta.env.VITE_API_URL.toString();
      const externalAxiosInstance = axios.create({
        baseURL: url.replace('api',''),

      })
      return await externalAxiosInstance.post(`reset-password`, { email,token,password,password_confirmation }).then(response => {


        this.isDialogVisible = true
        this.dialogData = {
          title: 'Sucesso!',
          message: 'Senha redefinida com sucesso!',
          type: 'success',
        }
        this.resetPasswordSucessfull = true;
        
      }).catch(error => {
        console.log(error)
        this.isDialogVisible = true
        this.dialogData = {
          title: 'Erro!',
          message: 'Erro ao redefinir senha!',
          type: 'error',
        }
      })
    },
    setIsDialogVisible(value) {
      this.isDialogVisible = value
    },
    setIsDialogData(value) {
      this.dialogData = value
    },

  },
})
